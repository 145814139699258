import {List} from "immutable";
import {
    CALC_TEACHER_CONSULTATIONS_JOURNAL,
    DELETE_TEACHER_CONSULTATION,
    FETCH_TEACHER_CONSULTATIONS_JOURNAL
} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHER_CONSULTATIONS_JOURNAL:
            return List(action.payload);
        case CALC_TEACHER_CONSULTATIONS_JOURNAL:
            return List(action.payload);
        case DELETE_TEACHER_CONSULTATION:
            return List(state.filter(i => i.id !== action.payload));
        default:
            return state;
    }
}
import {ROOT_URL} from "./index";
import axios from "axios";
import Cookie from "js-cookie";
import {toast} from "react-toastify";

export function writeErrors(error) {
    // Error
    if (error.response) {
        toast.error(error.response.data.exceptionMessage, {
            position: "top-right",
            autoClose: 5000,
        });
    }else{
        toast.error("Помилка", {
            position: "top-right",
            autoClose: 5000,
        });
    }
    const token = Cookie.get('OA.UM-Auth');
    axios.post(`${ROOT_URL}/v1/logs`, error, {
        headers: {"Authorization": `Bearer ${token}`},
    });


}

import React, {PureComponent} from "react";
import {connect} from "react-redux";
import matchSorter from 'match-sorter'
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {calcTeacherConsultations, deleteTeacherConsultations, fetchTeacherConsultations} from "./actions";

class TeacherConsultations extends PureComponent {
    render() {
        return (
            <div>
                <ReactTable
                    data={this.props.teacherConsultations.toArray()}
                    filterable
                    columns={[
                        {
                            Header: <Popup
                                trigger={<div> №</div>}
                                content="№ консультації"
                                hideOnScroll
                                on='hover'
                                style={{zIndex: 3001}}
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["numberLesson"]}),
                            filterAll: true,
                            accessor: "numberLesson",
                        },
                        {
                            Header: "Дата",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["date"]}),
                            filterAll: true,
                            accessor: "date",
                        },
                        {
                            Header: <Popup
                                trigger={<div>К-сть годин</div>}
                                content="Кількість годин"
                                hideOnScroll
                                on='hover'
                                style={{zIndex: 3001}}
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["hours"]}),
                            filterAll: true,
                            accessor: "hours",
                        },

                        {
                            Header: () => {
                                return (
                                    <Popup
                                        trigger={
                                            <Icon name='add' size={"big"}
                                                  color={"green"}
                                                  onClick={() => {
                                                      this.props.calcTeacherConsultations(this.props.teacher.value);
                                                  }}
                                            />
                                        }
                                        content='Перезавантажити кількість консультацій'
                                        on={'hover'}
                                        style={{zIndex: 3001}}
                                    />
                                );
                            },
                            accessor: "id",
                            width: 60,
                            Cell: ({row}) => {
                                return (<div style={{marginTop: "15px"}}><Popup
                                    trigger={
                                        <Icon name='trash'
                                              color={"red"}
                                              onClick={() => {
                                                  this.props.deleteTeacherConsultations(row.id);
                                              }}
                                        />
                                    }
                                    content='Видалити консультацію'
                                    on={'hover'}
                                    style={{zIndex: 3001}}
                                />
                                </div>)
                            },
                            sortable: false,
                            filterable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }

                        },
                    ]}
                    defaultPageSize={10}
                    style={{
                        height: "54vh",
                    }}
                    className="table-bordered text-center table-striped white-bg"
                    noDataText="Немає даних"
                    previousText='Попередня сторінка'
                    nextText='Наступна сторінка'
                    loadingText='Завантаження...'
                    pageText='Сторінка'
                    ofText='з'
                    rowsText='рядків'
                />
            </div>);
    }

}

function mapStateToProps(state) {
    return {
        teacherConsultations: state.get("teacherConsultations"),

    }
}


export default connect(mapStateToProps, {
    fetchTeacherConsultations,
    calcTeacherConsultations,
    deleteTeacherConsultations
})(TeacherConsultations)
import {List, Map} from "immutable"
import {
    DELETE_TEACHER__LESSON_PROPOSAL,
    DELETE_TEACHER_AUDIENCE_REQUEST,
    DELETE_TEACHER_EVENT,
    DELETE_TEACHER_EVENT_ON_LESSONS,
    EDIT_TEACHER_AUDIENCE_REQUESTS,
    EDIT_TEACHER_EVENT_INFO,
    EDIT_TEACHER_EVENT_INFO_ON_LESSONS,
    EDIT_TEACHER_LESSON_PROPOSAL,
    FETCH_TEACHER_AUDIENCE_REQUESTS,
    FETCH_TEACHER_EVENT_ON_LESSONS,
    FETCH_TEACHER_EVENTS,
    FETCH_TEACHER_LESSON_PROPOSALS,
    INSERT_TEACHER_AUDIENCE_REQUEST,
    INSERT_TEACHER_EVENT,
    INSERT_TEACHER_EVENT_ON_LESSONS,
    INSERT_TEACHER_LESSON_PROPOSAL,
} from "./types"

const initialState = Map({
    "teacherEventProposals": List(),
    "teacherEventOnLessonsProposals": List(),
    "audiencesRequests": List(),
    "lessonProposals": List(),
});
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHER_EVENTS:
            return state.set("teacherEventProposals", List(action.payload));
        case FETCH_TEACHER_EVENT_ON_LESSONS:
            return state.set("teacherEventOnLessonsProposals", List(action.payload));
        case FETCH_TEACHER_AUDIENCE_REQUESTS:
            return state.set("audiencesRequests", List(action.payload));
        case FETCH_TEACHER_LESSON_PROPOSALS:
            return state.set("lessonProposals", List(action.payload));
        case INSERT_TEACHER_EVENT:
            return state.set("teacherEventProposals", state.get("teacherEventProposals").insert(0, action.payload));
        case INSERT_TEACHER_EVENT_ON_LESSONS:
            return state.set("teacherEventOnLessonsProposals", state.get("teacherEventOnLessonsProposals").insert(0, action.payload));
        case INSERT_TEACHER_AUDIENCE_REQUEST:
            return state.set("audiencesRequests", state.get("audiencesRequests").insert(0, action.payload));
        case INSERT_TEACHER_LESSON_PROPOSAL:
            return state.set("lessonProposals", state.get("lessonProposals").insert(0, action.payload));
        case EDIT_TEACHER_EVENT_INFO:
            return state.set("teacherEventProposals", state.get("teacherEventProposals").set(state.get("teacherEventProposals").findIndex(i => i.id === action.payload.id), action.payload));
        case EDIT_TEACHER_EVENT_INFO_ON_LESSONS:
            return state.set("teacherEventOnLessonsProposals", state.get("teacherEventOnLessonsProposals").set(state.get("teacherEventOnLessonsProposals").findIndex(i => i.id === action.payload.id), action.payload));
        case EDIT_TEACHER_LESSON_PROPOSAL:
            return state.set("lessonProposals", state.get("lessonProposals").set(state.get("lessonProposals").findIndex(i => i.id === action.payload.id), action.payload));
        case EDIT_TEACHER_AUDIENCE_REQUESTS:
            return state.set("audiencesRequests", state.get("audiencesRequests").set(state.get("audiencesRequests").findIndex(i => i.id === action.payload.id), action.payload));
        case DELETE_TEACHER_EVENT:
            return state.set("teacherEventProposals", state.get("teacherEventProposals")
                .delete(state.get("teacherEventProposals")
                    .findIndex(i => i.id === action.payload)));
        case DELETE_TEACHER_EVENT_ON_LESSONS:
            return state.set("teacherEventOnLessonsProposals", state.get("teacherEventOnLessonsProposals")
                .delete(state.get("teacherEventOnLessonsProposals")
                    .findIndex(i => i.id === action.payload)));
        case DELETE_TEACHER_AUDIENCE_REQUEST:
            return state.set("audiencesRequests",
                state.get("audiencesRequests")
                    .delete(state.get("audiencesRequests")
                        .findIndex(i => i.id === action.payload)));
        case DELETE_TEACHER__LESSON_PROPOSAL:
            return state.set("lessonProposals", state.get("lessonProposals")
                .delete(state.get("lessonProposals")
                    .findIndex(i => i.id === action.payload)));
        default:
            return state;
    }
}
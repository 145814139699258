import React, {PureComponent} from 'react';
import Footer from "./components/Footer";
import AppHeader from "./components/AppHeader";
import AppMenu from "./components/AppMenu";
import Sidebar from "./components/Sidebar";
import "./styles.css";
import Cookie from "js-cookie";

class MainLayout extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            menuVisible: localStorage.getItem("OA.UM.Dekanat_menu") === null ? true : localStorage.getItem("OA.UM.Dekanat_menu") === 'true',
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    logOut() {
        Cookie.remove('OA.UM-Auth');
        Cookie.remove('OA.UM-Auth_data');
        this.props.history.push("/");
    };

    toggleMenu() {
        const {menuVisible} = this.state;
        this.setState({menuVisible: !menuVisible});
    };


    render() {
        const {menuVisible, showSettings} = this.state;
        localStorage.setItem("OA.UM.Dekanat_menu", (menuVisible).toString());
        if (this.props.path === "/documents/work-plan-load") {
            localStorage.setItem("OA.UM.Dekanat_work-plan-load_sidebar", (showSettings).toString());
        } else if (this.props.path === "/lessons") {
            localStorage.setItem("OA.UM.Dekanat_lessons_sidebar", (showSettings).toString());
        }
        return (
            <div className={"body-container white-bg"}>
                <nav className={`d-print-none sidebar ${menuVisible ? "d-initial" : "d-none"}`}>
                    <Sidebar {...this.props}/>
                </nav>
                <div className="layout-column">
                    <AppMenu className={"d-print-none"}
                             {...this.props}
                             showSidebar={this.toggleMenu}

                             logOut={this.logOut}
                             showSettings={this.props.showSettings}/>
                    <header className={"d-print-none"}>
                        <AppHeader {...this.props}/>
                    </header>
                    <main className="gray-bg">
                        <article>
                            {this.props.children}
                        </article>
                    </main>
                    <footer className={"d-print-none"}>
                        <Footer/>
                    </footer>
                </div>
            </div>

        );
    }

}


export default MainLayout;


import React, {PureComponent} from 'react';
import {getToken} from "./actions";
import {writeErrors} from "../../utils/logs";
import {toast} from "react-toastify";
import "./styles.css";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {GoogleLogin} from "react-google-login";
import {connect} from "react-redux";

class Login extends PureComponent {
    state = {
        loading: false
    };

    responseGoogle = (response) => {
        const {profileObj} = response;
        const email = profileObj.email;
        if (email.indexOf('@oa.edu.ua') >= 0) {
            localStorage.setItem("oa.um.dekanat-avatar", profileObj.imageUrl);
            this.setState({loading: true})
            this.props.getToken(email, profileObj.imageUrl);
        } else {
            toast.error('Ввійдіть в систему задопомогою пошти @oa.edu.ua', {
                position: "top-right",
                autoClose: 8000,
            });
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.props.history.replace("/user/lessons");
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }


    render() {
        const year = new Date().getFullYear();
        const currentYear = year > 2018 ? "-" + year : "";
        return (
            <div className="body-container ">

                <div className="form-signin">

                    <div className="text-center mb-4">
                        <img className="mb-1" src={process.env.PUBLIC_URL + `/images/oa_logo.png`}
                             alt="Герб Національного університету 'Острозька академія'"
                             width="150"
                             height="150"/>


                        <h1 className="mb-3">Система "Викладач"</h1>
                        <div className={`load-bar mb-2 ${this.state.loading ? "" : "d-none"}`}>
                            <div className="bar"/>
                            <div className="bar"/>
                            <div className="bar"/>
                        </div>


                    </div>

                    <GoogleLogin
                        clientId={'58385306310-g0t3bbnr3stnpkmdl7hmh880fgcqkki4.apps.googleusercontent.com'}
                        onSuccess={this.responseGoogle}
                        className="btn btn-lg btn-danger btn-block"
                    >
                        <Icon name="google"/>
                        <span>Вхід через пошту  @oa.edu.ua</span>
                    </GoogleLogin>{/*
                    <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>*/}
                    <p className="mt-5 mb-3 h5 text-muted text-center">© 2018{currentYear}</p>

                </div>
            </div>


        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.get("auth"),

    }
}

export default connect(mapStateToProps, {
    getToken,
})(Login)

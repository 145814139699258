import {Map} from "immutable"
import {FETCH_ANSWERS, FETCH_USER_EMAIL} from "./types";

const initialState = Map({email: ""});

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ANSWERS:
            return Map({
                email: state.get("email"),
                ...action.payload
            });
        case FETCH_USER_EMAIL:
            return state.set("email", action.payload);
        default:
            return state;
    }
}

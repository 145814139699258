import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import AddTopics from "../AddTopics/index"
import {addTopics} from "../../actions";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-datasheet/lib/react-datasheet.css';

class CreateTopicsModal extends PureComponent {

    state = {
        lessons: [],
        practise: [],
        lessonType: null,
        discipline: null,
        isLoadData: false,
        selectedIndex: 0

    };
    handleUpdateGrid = (grid) => {
        let name = 'lessons';
        if (this.state.selectedIndex === 1) {
            name = 'practise'
        }
        let object = {};
        object[name] = grid;
        this.setState(object);
    };

    handleChange = (value) => {
        this.setState({value})
    };


    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Механізм додавання тем"}
                width={"85vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() => {
                            this.props.hide("createTopicsModal")
                        }}>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary icon onClick={() => {
                            this.props.addTopics({
                                discipline: this.props.discipline.value,
                                teacher: this.props.teacher.value,
                                index: this.state.selectedIndex,
                                topics: this.state.selectedIndex === 1 ? this.state.practise : this.state.lessons,

                            });
                            this.props.hide("createTopicsModal");
                        }}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("createTopicsModal");
                }}
            >

                <Tabs onSelect={selectedIndex => this.setState({selectedIndex})}>
                    <TabList>
                        <Tab>Додати теми лекцій</Tab>
                        <Tab>Додати теми практичних</Tab>
                    </TabList>

                    <TabPanel>
                        <AddTopics handleUpdateTopics={this.handleUpdateGrid}/>
                    </TabPanel>
                    <TabPanel>
                        <AddTopics handleUpdateTopics={this.handleUpdateGrid}/>
                    </TabPanel>
                </Tabs>

            </PureModal>
        );
    }
}

export default compose(
    connectModal({
        name: 'createTopicsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        addTopics,
    }))(CreateTopicsModal);

import React, {Component} from "react";
import Header from "semantic-ui-react/dist/es/elements/Header/Header";
import {APP_NAME} from "../../../../utils";

export default class AppHeader extends Component {
    render() {
        document.title = `${APP_NAME}-${this.props.title}`;
        return (
            <Header as='h2' block className={"m-0 white-bg hidden-print"}>
                {this.props.title}
            </Header>
        );
    }

}
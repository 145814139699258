import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {show} from 'redux-modal'
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import CreateTopicsModal from "./components/CreateTopicsModal/index";
import EditTopicsModal from "./components/EditTopicsModal/index";
import {deleteTopic, editTopicInfo} from "./actions";
import {fetchLessonTypes} from "../University/LessonTypes/actions";
import {fetchDisciplinesControls} from "../University/DisciplinesControls/actions";

class Topics extends PureComponent {


    handleAddTopics() {
        this.props.show("createTopicsModal", {topic: this.props.row});
    };

    handleEditTopicInfo(row) {
        this.props.show("editTopicModal", {row});
    }

    handleDeleteTopic(id) {
        this.props.deleteTopic(id);
    };

    componentDidMount() {
        this.props.fetchLessonTypes();
        this.props.fetchDisciplinesControls();
    }


    render() {
        return (
            <div>
                <EditTopicsModal {...this.props}/>
                <CreateTopicsModal {...this.props}/>
                <div>
                    <ReactTable
                        data={this.props.rows.toArray()}
                        filterable
                        getTrProps={(state, rowInfo) => {
                            let background = "white";
                            if (rowInfo) {
                                if (rowInfo.row._original.hasDuplicates) {
                                    background = "#FF3232";
                                } else if (rowInfo.row._original.isArchived === 'Так') {
                                    background = "#cccccc";
                                }
                            }
                            return {
                                style: {
                                    background: background
                                }
                            };
                        }}
                        columns={[
                            {
                                Header: "№ заняття",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["lessonNumber"]}),
                                filterAll: true,
                                accessor: "lessonNumber",
                            },
                            {
                                Header: "Тема",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["topic"]}),
                                filterAll: true,
                                accessor: "topic",
                            }, {
                                Header: <Popup
                                    trigger={<div>Тип</div>}
                                    content="Тип предмету"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["disciplineType"]}),
                                filterAll: true,
                                accessor: "disciplineType",
                            },

                            {
                                Header: <Popup
                                    trigger={<div>Контроль</div>}
                                    content="Підсумковий контроль"
                                    hideOnScroll
                                    style={{zIndex: 3001}}
                                    on='hover'
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["disciplineControl"]}),
                                filterAll: true,
                                accessor: "disciplineControl",
                            },
                            {
                                Header: "Архів",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["isArchived"]}),
                                filterAll: true,
                                accessor: "isArchived",
                            },
                            {

                                Header: <Popup
                                    trigger={
                                        <Icon name='add' size={"big"}
                                              color={"green"}
                                              onClick={
                                                  this.handleAddTopics.bind(this)}/>
                                    }
                                    content='Додати запис'
                                    on={'hover'}
                                    style={{zIndex: 3001}}
                                />,
                                accessor: "id",
                                width: 100,
                                Cell: ({row}) => {
                                    return (<div style={{marginTop: "15px"}}>

                                        <Popup
                                            trigger={<Icon name={"edit"}
                                                           color={"blue"}
                                                           onClick={this.handleEditTopicInfo.bind(this, row._original)}
                                            />}
                                            content="Редагувати інформацію"
                                            hideOnScroll
                                            on='hover'
                                        />
                                        <Popup
                                            trigger={<Icon name={"trash"}
                                                           color={"red"}
                                                           onClick={this.handleDeleteTopic.bind(this, row._original.id)}
                                            />}
                                            content="Видалити запис"
                                            hideOnScroll
                                            on='hover'
                                        />
                                    </div>)
                                },
                                sortable: false,
                                filterable: false,
                                style: {
                                    cursor: "pointer",
                                    fontSize: 25,
                                    padding: "0",
                                    textAlign: "center",
                                    userSelect: "none"
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        style={{
                            height: "54vh",
                        }}
                        className="table-bordered table-striped white-bg text-center"
                        noDataText="Немає даних"
                        previousText='Попередня сторінка'
                        nextText='Наступна сторінка'
                        loadingText='Завантаження...'
                        pageText='Сторінка'
                        ofText='з'
                        rowsText='рядків'
                    />
                </div>

            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        rows: state.get("topics"),
        lessonTypes: state.get("lessonTypes"),
        disciplinesControls: state.get("disciplinesControls"),
    }
}

export default connect(mapStateToProps, {
    show,
    deleteTopic,
    editTopicInfo,
    fetchLessonTypes,
    fetchDisciplinesControls
})(Topics)
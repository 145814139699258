import {List} from "immutable"
import {
    DELETE_DISCIPLINE_CONTROL,
    EDIT_DISCIPLINES_CONTROLS,
    FETCH_DISCIPLINES_CONTROLS,
    INSERT_DISCIPLINE_CONTROL,
} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DISCIPLINES_CONTROLS:
            return List(action.payload);
        case EDIT_DISCIPLINES_CONTROLS:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_DISCIPLINE_CONTROL:
            return state.insert(0, action.payload);
        case DELETE_DISCIPLINE_CONTROL:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}
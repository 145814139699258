export const FETCH_WEEKS_FOR_SELECT = "FETCH_WEEKS_FOR_SELECT";
export const FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT = "FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT";
export const FETCH_TEACHER_DISCIPLINES_FOR_SELECT = "FETCH_TEACHER_DISCIPLINES_FOR_SELECT";




export const FETCH_AUDIENCE_FOR_SELECT = "FETCH_AUDIENCE_FOR_SELECT";

export const FETCH_FREE_AUDIENCES = "FETCH_FREE_AUDIENCE";





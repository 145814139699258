import React, {Component} from 'react';
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {connect} from "react-redux";
import {clearNotifications} from "../actions";

class NotificationHeader extends Component {
    static displayName = 'NotificationHeader';


    render() {
        return (
            <div className="rn-header">
                <div>
                    <h4>{this.props.children} <span style={{float: "right"}}>
                <Popup
                    trigger={<Icon name={"trash"} color={"red"} size={"large"}
                                   onClick={e => this.props.clearNotifications()}/>}
                    content="Видалити всі нотифікації"
                    hideOnScroll
                    on='hover'
                />
            </span>
                    </h4>
                </div>

            </div>
        );
    }

}

export default connect(null, {clearNotifications})(NotificationHeader)
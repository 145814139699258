import {combineReducers} from 'redux-immutable';
import {routerReducer} from "react-router-redux";
import {reducer as modal} from 'redux-modal';
import data4Select from "./components/Data4Select/reducer";
import disciplinesControls from "./scenes/University/DisciplinesControls/reducer";
import auth from "./scenes/Login/reducer";
import notifications from './components/Layouts/components/AppMenu/components/NotificationCenter/reducer';
import timetable from "./scenes/Timetable/reducer";
import departments from "./scenes/University/Departments/reducer";
import lessonsTimes from "./scenes/University/LessonsTimes/reducer";
import lessonsTypes from "./scenes/University/LessonTypes/reducer";
import disciplines from "./scenes/University/Disciplines/reducer";
import teachers from "./scenes/TeachersInfo/Teachers/reducer";
import teacherJournal from "./scenes/TeacherJournal/reducer";
import topics from "./scenes/Topics/reducer";
import teacherConsultations from "./scenes/TeacherConsultations/reducer";
import teacherLessons from "./scenes/TeacherLessons/reducer";
import teacherProposals from "./scenes/Proposals/reducer";
import answers from "./scenes/Answers/reducer";
import studyYears from "./scenes/University/StudyYear/reducer";
/*


import teacherStatistics from "./scenes/Documents/TeacherJournal/components/TeacherStatistics/reducer";
import teacherLoads from "./scenes/Documents/TeacherJournal/components/TeacherLoad/reducer";
*/

const reducers = combineReducers({
    modal,
    router: routerReducer,
    auth,

    answers,
    studyYears,

    notifications,
    data4Select,
    disciplinesControls,
    timetable,
    lessonsTimes,
    teachers,
    departments,
    lessonsTypes,
    disciplines,
    teacherJournal,
    teacherConsultations,
    teacherLessons,
    topics,


    teacherProposals
    /*
    teacherStatistics,
    teacherLoads*/
});

export default reducers;

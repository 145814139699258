import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {writeErrors} from "../../../../utils/logs";

class ErrorModal extends PureComponent {
    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        const {show, hide} = this.props;
        return (
            <PureModal
                header={<div><h4><Icon name={"info"}/>Якщо знайшли помилку!</h4></div>}
                footer={<div className={"pull-right"}>
                    <Button color={"red"} icon onClick={() => hide("errorModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                </div>}
                width={"40vw"}
                isOpen={show}
                draggable
            >
                <div>

                    <Icon name={"bug"} size={"massive"} style={{marginTop:"5vw"}} className="text-danger pull-left margin"/>


                        <strong>Якщо Ви знайшли помилку у роботі системи, допоможіть нам її виправити:</strong>
<div style={{marginLeft:"10vw"}}>
                        <ol >
                            <li>Опишіть, які дії Ви виконували, що призвели до помилки.</li>
                            <li>Зробіть screenshot сторінки з помилкою (Alt+PrintScreen для вибору окремого вікна, а не
                                всього екрану та вставте у MS Paint або ін. граф. редактор).
                            </li>
                            <li>
                                Надішліть інформацію на пошти розробника:
                                <a href="mailto:andrii.shpak@oa.edu.ua">andrii.shpak@oa.edu.ua</a>.
                            </li>
                        </ol>
                        <p className="m-l-extra">Дякуємо за співпрацю!</p>
</div>

                </div>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'errorModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide
    })
)(ErrorModal);
import React, {PureComponent} from "react";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import DatePicker from 'react-date-picker'
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {connect} from "react-redux";
import "./styles.css";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {fetchFreeAudiences} from "../../components/Data4Select/actions";
import {writeErrors} from "../../utils/logs";
import {getLessons, NoOptionsMessage} from "../../utils";
import Select from "react-select";

class FreeAudiences extends PureComponent {
    state = {
        date: new Date(),
        compAudience: false,
        projectorAudience: false,
        freeAudience: false,
        lessonNumbers: [],
    };
    renderAudienceRows = (audiences, lessonNumber) => {
        let rows = [];
        if (audiences) {
            let i = 0;
            const number = Math.ceil(audiences.length / 10);
            if (audiences.length === 0) {
                rows.push(<Table.Row>
                    <Table.Cell error
                                className={"w-3"}
                                textAlign={"center"}
                                verticalAlign={"middle"}>
                        {lessonNumber}</Table.Cell>
                    <Table.Cell
                        colSpan={10}/>
                </Table.Row>);
            }
            for (let k = 0; k < number; k++) {
                let cells = [];
                if (k === 0) {
                    cells.push(<Table.Cell error textAlign={"center"}
                                           verticalAlign={"middle"} key={i}
                                           rowSpan={number}>{lessonNumber}</Table.Cell>);
                }
                for (let j = 0; j < 10; j++) {

                    if (typeof audiences[i] === 'undefined') {
                        cells.push(<Table.Cell key={i}/>);
                    } else {
                        cells.push(this.renderCell(audiences[i], i));
                    }
                    ++i;
                }
                rows.push(<Table.Row key={k}>{cells}</Table.Row>);
            }
        }

        return rows;
    }
    getIcon = (audience) => {
        if (audience.projectorAudience && audience.compAudience) {
            return (
                <span>
                    <Icon className={`${audience.projectorAudience ? "projector" : ""}`}/>
                    <Icon className={`${audience.compAudience ? "computer" : ""}`}/>
                </span>
            )
        } else if (audience.projectorAudience) {
            return <Icon className={`${audience.projectorAudience ? "projector" : ""}`}/>
        } else if (audience.compAudience) {
            return <Icon className={`${audience.compAudience ? "computer" : ""}`}/>
        }
    }
    renderCell = (audience, i) => {
        return <Table.Cell key={`cell_${i + i}`} positive={!audience.freeAudience}>


            {audience.freeAudience && audience.groups ? <Popup
                trigger={
                    this.getCell(audience)
                }
                content={
                    <div>
                        {audience.groups}
                        <em> {audience.teacher}</em>
                    </div>
                }
                hideOnScroll
                on='hover'
                style={{zIndex: 3001}}
            /> : this.getCell(audience)}


        </Table.Cell>
    }
    getCell = (audience) => {
        return (<div>
            {this.getIcon(audience)}
            <span>{audience.name}</span>
        </div>);
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        return (
            <div style={{height: "78vh", overflow: "auto"}}>
                <Table celled compact={"very"}>
                    <Table.Header textAlign={"center"} verticalAlign={"middle"}>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Номер пари</Table.HeaderCell>
                            <Table.HeaderCell>Лише вільні аудиторії</Table.HeaderCell>
                            <Table.HeaderCell>Аудиторії з проектором</Table.HeaderCell>
                            <Table.HeaderCell>Ком'ютерні класи</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className={"w-20"} textAlign={"center"} verticalAlign={"middle"}>
                                <DatePicker
                                    onChange={(value) => this.setState({date: value})}
                                    locale={"uk-UA"}
                                    value={this.state.date}
                                />
                            </Table.Cell>
                            <Table.Cell className={"w-20"}>
                                <Select
                                    options={getLessons()}
                                    onChange={(selectedValue) => {
                                        this.setState({lessonNumbers: selectedValue})

                                    }}
                                    isMulti
                                    value={this.state.lessonNumbers}
                                    noOptionsMessage={NoOptionsMessage}
                                    placeholder={"Виберіть номери пар"}
                                />
                            </Table.Cell>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                <div className="pretty p-default  p-curve p-fill" style={{fontSize: "24px"}}>
                                    <input onChange={() =>
                                        this.setState(
                                            {freeAudience: !this.state.freeAudience})
                                    }
                                           type="checkbox" defaultChecked={this.state.freeAudience}/>
                                    <div className="state p-info">
                                        <label/>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                <div className="pretty p-default  p-curve p-fill" style={{fontSize: "24px"}}>
                                    <input
                                        onChange={() =>
                                            this.setState(
                                                {projectorAudience: !this.state.projectorAudience})
                                        }
                                        type="checkbox" defaultChecked={this.state.projectorAudience}/>
                                    <div className="state p-info">
                                        <label/>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                <div className="pretty p-default  p-curve p-fill" style={{fontSize: "24px"}}>
                                    <input
                                        onChange={() =>
                                            this.setState(
                                                {compAudience: !this.state.compAudience})
                                        }
                                        type="checkbox" defaultChecked={this.state.compAudience}/>
                                    <div className="state p-info">
                                        <label/>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                <Button primary onClick={() => {
                                    this.props.fetchFreeAudiences(
                                        {
                                            date:
                                            this.state.date,
                                            lessonNumbers: this.state.lessonNumbers.map(i => i.value).join(","),
                                            compAudience: this.state.compAudience,
                                            projectorAudience: this.state.projectorAudience,
                                            freeAudience: this.state.freeAudience,
                                        })
                                }
                                }>Переглянути</Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <div>
                    {
                        this.props.audiences.size > 0 ?
                            <Table celled compact={"very"} textAlign={"center"} verticalAlign={"middle"}>
                                <Table.Body>
                                    {

                                        this.props.audiences.map(audiences => {
                                            return this.renderAudienceRows(
                                                audiences.audiences, audiences.lessonNumber)
                                        })
                                    }
                                </Table.Body>
                            </Table> : <div className={"text-center"}>Немає даних</div>
                    }

                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        audiences: state.get("data4Select").get("freeAudience"),
    }
}

export default connect(mapStateToProps, {
    fetchFreeAudiences
})(FreeAudiences)
import axios from "axios";
import {FETCH_ANSWERS, FETCH_USER_EMAIL} from "./types";
import {ROOT_URL} from "../../utils";
import Cookies from "js-cookie";

export const fetchAnswers = (studyYear, email) => {
    const request = axios.get(`https://feedback.oa.edu.ua/api/answers`, {params: {studyYear, email}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_ANSWERS, payload: data});
        })
            .catch(() => {
            });
    }
};
export const fetchUserEmail = () => {
    const token = Cookies.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/email`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_USER_EMAIL, payload: data});
        })
            .catch(() => {
            });
    }
};

import {List, Map} from "immutable"
import {
    FETCH_AUDIENCE_FOR_SELECT,
    FETCH_FREE_AUDIENCES,
    FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT,
    FETCH_TEACHER_DISCIPLINES_FOR_SELECT,
    FETCH_WEEKS_FOR_SELECT
} from "./types";

const initialState = Map({
    "weeks": List(),
    "audiences": List(),
    "specialitiesWithStudyLevel": List(),
    "freeAudience": List(),
    "disciplines": List(),
});

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_WEEKS_FOR_SELECT:
            return state.set("weeks", List(action.payload));
        case FETCH_FREE_AUDIENCES:
            return state.set("freeAudience", List(action.payload));
        case FETCH_AUDIENCE_FOR_SELECT:
            return state.set("audiences", List(action.payload));
        case FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT:
            return state.set("specialitiesWithStudyLevel", List(action.payload));
        case FETCH_TEACHER_DISCIPLINES_FOR_SELECT:
            return state.set("disciplines", List(action.payload));
        default:
            return state;
    }
}
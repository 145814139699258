import React, {Component} from "react";
import 'react-table/react-table.css'
import {writeErrors} from "../../utils/logs";
import TeacherJournalFilter from '../TeacherJournalFilter';
import LessonInfo from "../LessonInfo";
import TeacherConsultations from "../TeacherConsultations";
import TeacherLessons from "../TeacherLessons";
import Topics from "../Topics";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {fetchDisciplines} from "../University/Disciplines/actions";
import {connect} from "react-redux";

class TeacherJournal extends Component {
    state = {
        discipline: null,
        teacher: null,
    };

    onDisciplineChange(discipline, teacher) {
        this.setState({discipline, teacher});
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        let teacher = this.state.teacher;
        if (teacher === null && this.props.departments.size > 0) {
            const filter = this.props.teachers.filter(i => i.isSelected);
            if (filter.size > 0) {
                teacher = filter.get(0);
                this.props.fetchDisciplines(teacher.value)
            }

        }
        return (
            <div>
                <div>
                    <TeacherJournalFilter
                        handleDisciplineChange={this.onDisciplineChange.bind(this)}
                        discipline={this.state.discipline}
                        teacher={teacher}/>
                </div>
                <div>
                    <LessonInfo/>
                </div>


                <div>
                    <Tabs>
                        <TabList>
                            <Tab>Облік навтаження</Tab>
                            <Tab>Журнал пар</Tab>
                            <Tab>Довідник пар</Tab>
                            <Tab>Журнал консультацій</Tab>
                            <Tab>Дод. навантаження</Tab>
                        </TabList>

                        <TabPanel>
                            Реалізувати!!!
                            {/*{this.state.discipline ? <TeacherStatistics {...this.state}/> :
                                <h3 className={"text-center"}> Виберіть викладача та предмет</h3>}*/}
                        </TabPanel>
                        <TabPanel>
                            {this.state.discipline ? <TeacherLessons {...this.state}/> :
                                <h3 className={"text-center"}> Виберіть викладача та предмет</h3>}
                        </TabPanel>
                        <TabPanel>
                            {this.state.discipline ? <Topics {...this.state}/> :
                                <h3 className={"text-center"}> Виберіть викладача та предмет</h3>}
                        </TabPanel>
                        <TabPanel>
                            {this.state.discipline ? <TeacherConsultations {...this.state}/> :
                                <h3 className={"text-center"}> Виберіть викладача та предмет</h3>}
                        </TabPanel>
                        <TabPanel>
                            {/*  Реалізувати!!!
                            {this.state.discipline ? <TeacherLoad {...this.state}/> :
                                <h3 className={"text-center"}> Виберіть викладача та предмет</h3>}*/}
                        </TabPanel>
                    </Tabs>
                </div>

            </div>);
    }

}

function mapStateToProps(state) {
    return {
        departments: state.get("departments"),
        teachers: state.get("teachers"),
    }
}

export default connect(mapStateToProps, {fetchDisciplines})(TeacherJournal);
import axios from "axios/index";
import {ROOT_URL} from "../../utils";
import Cookie from "js-cookie";
import {
    FETCH_AUDIENCE_FOR_SELECT,
    FETCH_FREE_AUDIENCES,
    FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT,
    FETCH_TEACHER_DISCIPLINES_FOR_SELECT,
    FETCH_WEEKS_FOR_SELECT
} from "./types";
import {writeErrors} from "../../utils/logs";

export function fetchDisciplines4Select(inputValue) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplines4Select`, {
        params: {inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })

}

export function fetchTeachers4Select(inputValue) {
    let token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/teachers4Select`, {
        params: {inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })
}

export function fetchWeeks4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/weeks4Select`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_WEEKS_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function fetchSpecialities4SelectWithStudyLevel() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/specialities4SelectWithStudyLevel`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function fetchTeacherDisciplines() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplines4Teacher`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_DISCIPLINES_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function fetchAudiences4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/audiences4Select`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_AUDIENCE_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchFreeAudiences(model) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/freeAudiences`, {
        params: model,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_FREE_AUDIENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



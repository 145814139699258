import {Map} from "immutable";
import {FETCH_TEACHER_JOURNAL} from "./types";

const initialState = Map();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHER_JOURNAL:
            return Map(action.payload);

        default:
            return state;
    }
}
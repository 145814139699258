export const FETCH_TEACHER_EVENTS = "FETCH_TEACHER_EVENTS";

export const INSERT_TEACHER_EVENT = "INSERT_TEACHER_EVENT";

export const DELETE_TEACHER_EVENT = "DELETE_TEACHER_EVENT";

export const EDIT_TEACHER_EVENT_INFO = "EDIT_TEACHER_EVENT_INFO";

export const FETCH_TEACHER_EVENT_ON_LESSONS = "FETCH_TEACHER_EVENT_ON_LESSONS";

export const INSERT_TEACHER_EVENT_ON_LESSONS  = "INSERT_TEACHER_EVENT_ON_LESSONS";

export const DELETE_TEACHER_EVENT_ON_LESSONS  = "DELETE_TEACHER_EVENT_ON_LESSONS";

export const EDIT_TEACHER_EVENT_INFO_ON_LESSONS  = "EDIT_TEACHER_EVENT_INFO_ON_LESSONS";

// Audience
export const FETCH_TEACHER_AUDIENCE_REQUESTS = "FETCH_TEACHER_AUDIENCE_REQUESTS";

export const INSERT_TEACHER_AUDIENCE_REQUEST = "INSERT_TEACHER_AUDIENCE_REQUEST";

export const EDIT_TEACHER_AUDIENCE_REQUESTS = "EDIT_TEACHER_AUDIENCE_REQUESTS";

export const DELETE_TEACHER_AUDIENCE_REQUEST = "EDIT_TEACHER_AUDIENCE_REQUEST";
// Lesson
export const FETCH_TEACHER_LESSON_PROPOSALS = "FETCH_TEACHER_LESSON_PROPOSALS";

export const INSERT_TEACHER_LESSON_PROPOSAL = "INSERT_TEACHER_LESSON_PROPOSAL";

export const EDIT_TEACHER_LESSON_PROPOSAL = "EDIT_TEACHER_LESSON_PROPOSAL";

export const DELETE_TEACHER__LESSON_PROPOSAL = "EDIT_TEACHER__LESSON_PROPOSAL";

import axios from "axios";
import Cookie from 'js-cookie'
import {LOGIN} from "./types";
import {writeErrors} from "../../utils/logs";
import {ROOT_URL} from "../../utils/index";

export function getToken(email, password) {
    let url = `https://dekanat.oa.edu.ua`;
    /*if (process.env.NODE_ENV === 'development') {
        url = `http://10.211.55.11:8012`;
    }*/
    sessionStorage.setItem("login", "false");
    const response = axios.post(`${url}/Token`, "userName=" + encodeURIComponent(email) +
        "&password=" + encodeURIComponent(password) +
        "&grant_type=password", {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return (dispatch) => {
        response.then(({data}) => {
            const token = data.access_token;
            axios.get(`${ROOT_URL}/v1/userNameWithInitials`, {
                params: {email},
            }).then(({data}) => {
                Object.keys(data).forEach((key) => {
                    localStorage.setItem(`OA.UM.Dekanat_${key}`, data[key])
                });
            });
            Cookie.set('OA.UM-Auth', token, {expires: 0.4});
            axios.get(`${url}/api/v1/userInfo`, {headers: {"Authorization": `Bearer ${token}`}})
                .then(({data}) => {
                    Cookie.set('OA.UM-Auth_data', data);
                    sessionStorage.setItem("login", "true");
                    dispatch({type: LOGIN, payload: data})
                });
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

/*export function fetchUserNameWithInitials(email) {
    const request = axios.get(`${ROOT_URL}/v1/userNameWithInitials`, {
        params: {email},
    });
    return (dispatch) => {
        request.then(({data}) => {
            Object.keys(data).map((key) => {
                localStorage.setItem(`OA.UM.Dekanat_${key}`, data[key])
            });


        }).catch((error) => {
            writeErrors(error);
        });
    }
}*/

import axios from "axios";
import {ROOT_URL} from "../../utils/index";
import {
    DELETE_TEACHER__LESSON_PROPOSAL,
    DELETE_TEACHER_AUDIENCE_REQUEST,
    DELETE_TEACHER_EVENT,
    DELETE_TEACHER_EVENT_ON_LESSONS,
    EDIT_TEACHER_AUDIENCE_REQUESTS,
    EDIT_TEACHER_EVENT_INFO,
    EDIT_TEACHER_EVENT_INFO_ON_LESSONS,
    EDIT_TEACHER_LESSON_PROPOSAL,
    FETCH_TEACHER_AUDIENCE_REQUESTS,
    FETCH_TEACHER_EVENT_ON_LESSONS,
    FETCH_TEACHER_EVENTS,
    FETCH_TEACHER_LESSON_PROPOSALS,
    INSERT_TEACHER_AUDIENCE_REQUEST,
    INSERT_TEACHER_EVENT,
    INSERT_TEACHER_EVENT_ON_LESSONS,
    INSERT_TEACHER_LESSON_PROPOSAL,
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../utils/logs";


export function fetchTeacherProposals() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/proposals`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_EVENTS, payload: data.events})
            dispatch({type: FETCH_TEACHER_EVENT_ON_LESSONS, payload: data.eventsOnLessons})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchTeacherAudienceRequests() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/proposals/requests`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_AUDIENCE_REQUESTS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchTeacherLessonProposals() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/proposals/lessons`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_LESSON_PROPOSALS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editTeacherProposal(teacherProposal) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/proposal/edit`, teacherProposal, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({
                type: teacherProposal.eventType === 1 ?
                    EDIT_TEACHER_EVENT_INFO :
                    EDIT_TEACHER_EVENT_INFO_ON_LESSONS,
                payload: data
            })
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editTeacherLessonProposal(teacherProposal) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/proposal/lessons/edit`, teacherProposal, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHER_LESSON_PROPOSAL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editTeacherAudienceRequests(teacherProposal) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/proposal/requests/edit`, teacherProposal, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHER_AUDIENCE_REQUESTS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacherProposal(teacherProposal) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/proposal/create`, teacherProposal, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({
                type:
                    teacherProposal.eventType === 1 ?
                        INSERT_TEACHER_EVENT :
                        INSERT_TEACHER_EVENT_ON_LESSONS,
                payload: data
            })
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function insertTeacherLessonProposal(teacherProposal) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/proposal/lesson/create`, teacherProposal, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_LESSON_PROPOSAL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function insertTeacherAudienceRequest(teacherProposal) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/proposal/request/create`, teacherProposal, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_AUDIENCE_REQUEST, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherProposal(id, eventType) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/proposal/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({
                type: eventType === 0 ?
                    DELETE_TEACHER_EVENT :
                    DELETE_TEACHER_EVENT_ON_LESSONS, payload: id
            })
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherAudienceRequest(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/proposal/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_AUDIENCE_REQUEST, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherLessonProposal(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/proposal/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER__LESSON_PROPOSAL, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

import React from 'react';
import Route from "react-router-dom/es/Route";


const AppRoute = ({component: Component, layout: Layout, ...rest}) => (
    <Route {...rest} render={props => (
        <Layout {...rest} {...props}>
            <Component {...props} />
        </Layout>
    )}/>
);
export default AppRoute;
import {List} from "immutable"
import {FETCH_LESSON_SCHEDULE} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_LESSON_SCHEDULE:
            return List(action.payload);
        default:
            return state;
    }
}
import React, {PureComponent} from "react";
import PureModal from "react-pure-modal";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {connect} from "react-redux";
import {connectModal, hide} from "redux-modal";
import {compose} from "redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {editTeacherProposal, insertTeacherProposal} from "../../../actions";
import DatePicker from "react-date-picker";
import Select from "react-select";
import {getLessons, NoOptionsMessage} from "../../../../../utils";

class TeacherProposalsModal extends PureComponent {
    handleSave = () => {
        const object = {
            title: this.state.title,
            id: this.state.id,
            start: this.state.start,
            end: this.state.end,
            eventType: this.state.selectedIndex + 1,
            lessonNumbers: this.state.values.map(i => i.value).join(',')
        };
        if (this.state.isCreate) {
            this.props.insertTeacherProposal(object);
        } else {
            this.props.editTeacherProposal(object);
        }

        this.props.hide("teacherProposalsModal");
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.row) {
            row = props.row;
        }
        this.state = {
            id: props.isCreate ? '' : row.id,
            isCreate: props.isCreate,
            title: row.title,
            start: row.start ? new Date(row.start) : new Date(),
            end: row.end ? new Date(row.end) : new Date(),
            values: props.isCreate || props.selectedIndex === 0 ? [] : row.values.map(({label, value}) => {
                return {
                    value,
                    label
                }
            }),
            selectedIndex: props.selectedIndex
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про занятості викладача"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherProposalsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teacherProposalsModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className={"w-20"}>
                                Причнина зайнятості. Наприклад - "Конференція"
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({title: e.target.value})}
                                    className={"form-control"}
                                    defaultValue={this.state.title}
                                    placeholder={'Вкажіть причину зайнятості викладача'}
                                    required/>
                            </Table.Cell>
                        </Table.Row>
                        {this.state.selectedIndex !== 0 && <Table.Row>
                            <Table.Cell>
                                № пар
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={getLessons()}
                                    onChange={(selected) => {
                                        this.setState({
                                            values: selected,
                                        })
                                    }}
                                    defaultValue={this.state.values}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    isMulti
                                    placeholder={"Виберіть номери пар"}
                                />
                            </Table.Cell>
                        </Table.Row>}
                        <Table.Row>
                            <Table.Cell>
                                Дата початоку події
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({start: value})}
                                    locale={"uk-UA"}
                                    value={this.state.start}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Дата закінчення події
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({end: value})}
                                    locale={"uk-UA"}
                                    value={this.state.end}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }

}


export default compose(
    connectModal({
        name: 'teacherProposalsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        editTeacherProposal,
        insertTeacherProposal,

    }))(TeacherProposalsModal);
import axios from "axios";
import {FETCH_DISCIPLINES} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";


export function fetchDisciplines(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/journal/disciplines`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DISCIPLINES, payload: data})
        })
    }
}


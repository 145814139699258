import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_DISCIPLINE_CONTROL,
    EDIT_DISCIPLINES_CONTROLS,
    FETCH_DISCIPLINES_CONTROLS,
    INSERT_DISCIPLINE_CONTROL,
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchDisciplinesControls() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplinesControl`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DISCIPLINES_CONTROLS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editDisciplinesControlsInfo(row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/disciplinesControl/edit`, row, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_DISCIPLINES_CONTROLS, payload: row})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertDisciplinesControl(row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/disciplinesControl/create`, row, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_DISCIPLINE_CONTROL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteDisciplinesControl( id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/disciplinesControl/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_DISCIPLINE_CONTROL, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


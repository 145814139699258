import {List} from "immutable"
import {CLEAR_NOTIFICATIONS, FETCH_NOTIFICATIONS} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            return List(action.payload);
        case CLEAR_NOTIFICATIONS:
            return List(action.payload);
        default:
            return state;
    }
}
import React, {PureComponent} from "react";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import "./styles.css";
export default class AddTopics extends PureComponent {
    generateArray = () => {
        let rows = [[{value: "№", readOnly: true, width: "3vw"}, {value: "Тема",readOnly: true,}]];
        for (let i = 1; i <= 100; i++) {
            rows.push([{value: i, readOnly: true, width: "3vw"}, {value: ""}])
        }
        return rows;
    };
    state = {
        grid: this.generateArray()
    };

    render() {
        return (
            <div>
                <ReactDataSheet
                    data={this.state.grid}
                    valueRenderer={(cell) => cell.value}
                    className={"white-bg w-100"}
                    onCellsChanged={
                        changes => {
                            const grid = this.state.grid.map(row => [...row]);
                            changes.forEach(({cell, row, col, value}) => {
                                grid[row][col] = {...grid[row][col], value}
                            });
                            this.props.handleUpdateTopics(grid);
                            this.setState({grid})

                        }

                    }
                />
            </div>
        );
    }

}
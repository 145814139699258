import React, {PureComponent} from "react";
import {fetchDepartmentTimetable} from "../actions";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import ReactToPrint from "react-to-print";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import "./styles.css";
import {fetchTeachers4Select, fetchWeeks4Select} from "../../../components/Data4Select/actions";
import {writeErrors} from "../../../utils/logs";
import {loadingMessage, NoOptionsMessage} from "../../../utils";
import AsyncSelect from 'react-select/lib/Async';
import Select from "react-select";
import {getPagePrintStyle} from "../printPageStyle";

class DepartmentTimetable extends PureComponent {
    state = {
        week: null,
        teachers: localStorage.getItem('OA.UM-timetable_department_teachers') !== null && localStorage.getItem('OA.UM-timetable_department_teachers').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-timetable_department_teachers')) : []
    };

    fetchDepartmentTimetable = (object) => {
        localStorage.setItem('OA.UM-timetable_department_teachers', JSON.stringify(object.teachers));
        localStorage.setItem('OA.UM-timetable_department_week', JSON.stringify(object.week));
        object.teachers = object.teachers.map(i => i.value).join(",");
        object.week = object.week.value || '';
        this.props.fetchDepartmentTimetable(object);
    };

    loadOptions = inputValue => fetchTeachers4Select(inputValue);

    prevWeek = () => {
        let index = this.state.week ? this.props.weeks.findIndex(i => i.value === this.state.week.value) - 1 : this.props.weeks.findIndex(i => i.isSelected) - 1;
        if (index > -1) {
            const week = this.props.weeks.get(index);
            this.setState({
                week: week,

            });
            this.fetchDepartmentTimetable({
                week: week,
                teachers: this.state.teachers,
            })
        }

    };

    nextWeek = () => {
        let index = this.state.week ? this.props.weeks.findIndex(i => i.value === this.state.week.value) + 1 : this.props.weeks.findIndex(i => i.isSelected) + 1;
        if (index < this.props.weeks.size) {
            const week = this.props.weeks.get(index);
            this.setState({
                week: week,

            });
            this.fetchDepartmentTimetable({
                week: week,
                teachers: this.state.teachers,
            })
        }
    };
    renderLessons = (lessons) => {
        return lessons.map(lesson => {
            return (
                <div key={lesson.id} className={"text-center"} style={{backgroundColor: lesson.color}}>
                    <Popup
                        trigger={<span> {lesson.disciplineShortName}</span>}
                        content={lesson.discipline}
                        hideOnScroll
                        on='hover'
                    />
                    <span> {lesson.lessonType}</span>
                    <Popup
                        trigger={<strong> {lesson.groups}</strong>}
                        content={lesson.universityGroupsFlow}
                        hideOnScroll
                        on='hover'
                    />
                    <span> {lesson.number}</span>
                    <span>{lesson.maxNumber}</span>
                    <Popup
                        trigger={<span> {lesson.teacher}</span>}
                        content={lesson.teacherFullName}
                        hideOnScroll
                        on='hover'
                    />
                    <em>{lesson.audience}</em>
                </div>
            );
        })
    };
    renderDays = (cells) => {
        return cells.map((lessonCell, i) => {
            return (
                <Table.HeaderCell key={`cell_${i}`} className={"mark"}
                                  verticalAlign={"middle"} textAlign={"center"} colSpan={lessonCell.colSpan}
                                  rowSpan={lessonCell.rowSpan}>
                    <span>{lessonCell.value ? lessonCell.value : ''}</span>
                    <em> {lessonCell.date ? `(${lessonCell.date})` : ''}</em>
                </Table.HeaderCell>
            )

        })
    };
    renderCells = (cells) => {
        return cells.map((lessonCell, i) => {
            const {lessons} = lessonCell;
            if (lessons.length > 0) {
                return (<Table.Cell key={`cell_${i}`}
                                    verticalAlign={"middle"}
                                    textAlign={"center"}
                                    colSpan={lessonCell.colSpan}
                                    rowSpan={lessonCell.rowSpan}>
                    {this.renderLessons(lessons)}
                </Table.Cell>);
            } else {
                const {value} = lessonCell;
                let teacher = value;
                if (value.length > 5) {
                    teacher = value.replace(' ', String.fromCharCode(160));
                    teacher = teacher.replace('. ', "." + String.fromCharCode(160));
                }
                return (
                    <Table.Cell key={`cell_${i}`}
                                verticalAlign={"middle"}
                                textAlign={"center"}
                                colSpan={lessonCell.colSpan} rowSpan={lessonCell.rowSpan}>
                        {teacher}
                    </Table.Cell>
                );
            }

        })
    };
    renderTable = (tableBody) => {
        return tableBody.map((cells, rowIdx) => {
            return (<Table.Row key={`row_${rowIdx}`} className={"text-center"}>
                {this.renderCells(cells)}
            </Table.Row>)
        })

    };
    renderTableFilters = (currentWeek) => {
        return (<div>
            <Table compact={"very"} className={"table table-bordered"}>
                <Table.Header className={"text-center"}>
                    <Table.Row>
                        <Table.HeaderCell className={"text-center"}>Тижні</Table.HeaderCell>
                        <Table.HeaderCell className={"text-center"}>Викладач</Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className={"w-30"}>
                            <div className={"w-100"}
                                 style={{
                                     display: "inline-flex",
                                     direction: "row"
                                 }}>
                                <div>
                                    <Button icon='left chevron'
                                            onClick={
                                                this.prevWeek
                                            }
                                            style={{
                                                padding: "0.78571429em 0.8571429em 1.1em",
                                                margin: "0"
                                            }}/>
                                </div>
                                <div style={{
                                    flex: "1 0 auto"
                                }}>
                                    <Select
                                        options={this.props.weeks.toArray()}
                                        onChange={selectedValue => {
                                            let week = null;
                                            if (selectedValue !== null) {
                                                week = selectedValue;
                                            }
                                            this.setState({week});
                                            this.fetchDepartmentTimetable({
                                                week: week || currentWeek,
                                                teachers: this.state.teachers,
                                            })
                                        }}
                                        value={this.state.week || currentWeek}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Виберіть навчальний тиждень"}
                                    />
                                </div>
                                <div>
                                    <Button icon='right chevron' onClick={
                                        this.nextWeek
                                    }
                                            style={{
                                                padding: "0.78571429em 0.8571429em 1em"
                                            }}/>
                                </div>
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={this.loadOptions}
                                onChange={(selectedValue) => {
                                    this.setState({teachers: selectedValue});
                                    this.fetchDepartmentTimetable({
                                        week: this.state.week || currentWeek,
                                        teachers: selectedValue,
                                    })
                                }}
                                defaultValue={this.state.teachers}
                                noOptionsMessage={NoOptionsMessage}
                                loadingMessage={loadingMessage}
                                isClearable
                                isMulti
                                placeholder={"Вкажіть викладача"}
                            />

                        </Table.Cell>
                        <Table.Cell style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                        }} className={"w-10"}>
                            <ReactToPrint
                                trigger={() => <Button icon color={"teal"}> <Icon
                                    name={"print"}/> Друк</Button>}
                                content={() => this.componentRef}
                                copyStyles={false}
                                pageStyle={getPagePrintStyle()}
                            />

                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>);
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    componentDidMount() {
        this.props.fetchWeeks4Select();
        const teachers = localStorage.getItem('OA.UM-timetable_department_teachers') !== null && localStorage.getItem('OA.UM-timetable_department_teachers').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-timetable_department_teachers')) : [];

        this.props.fetchDepartmentTimetable({
            teachers: teachers.map(i => i.value).join(","),
        });
    }

    render() {
        let weekValue = this.state.week;
        if (!weekValue && this.props.weeks.size > 0) {
            const filter = this.props.weeks.filter(i => i.isSelected);
            weekValue = filter.size > 0 ? filter.get(0) : this.props.weeks.get(0);
        }
        if (this.props.lessons.size < 1) {
            return (
                <div>
                    {this.renderTableFilters(weekValue)}
                    <div className="text-center">Немає даних</div>
                </div>
            )
        }

        const tableHeader = this.props.lessons.first();
        const tableBody = this.props.lessons.shift();

        return (
            <div style={{overflow: "auto", height: "78vh"}}>
                {this.renderTableFilters(weekValue)}
                <div ref={el => (this.componentRef = el)}>
                    <Table celled compact={"very"} className={"timetable"}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell key={"headerCell_1"} className={"mark"} colSpan={2}/>
                                {this.renderDays(tableHeader)}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.renderTable(tableBody)
                            }

                        </Table.Body>
                    </Table>

                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        weeks: state.get("data4Select").get("weeks"),
        lessons: state.get("timetable").get("department"),
    }
}

export default connect(mapStateToProps, {
    fetchWeeks4Select,
    fetchDepartmentTimetable
})(DepartmentTimetable);
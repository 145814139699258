import React from 'react';
import PropTypes from "prop-types";

const NotificationFooter = props => <div className="rn-footer">{props.children}</div>;

NotificationFooter.displayName = 'NotificationHeader';
NotificationFooter.propTypes = {
    children: PropTypes.node.isRequired
};
export default NotificationFooter;

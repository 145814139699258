import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {deleteTeacherAudienceRequest, fetchTeacherAudienceRequests,} from "../actions"
import {fetchLessonTypes} from "../../University/LessonTypes/actions";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import TeacherAudienceProposalsModal from "./components/TeacherAudienceProposalsModal"
import {show} from "redux-modal";
import {fetchAudiences4Select, fetchTeacherDisciplines} from "../../../components/Data4Select/actions";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

class TeacherProposals extends PureComponent {
    state = {
        selectedIndex: 0
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    componentDidMount() {
        this.props.fetchLessonTypes();
        this.props.fetchAudiences4Select();
        this.props.fetchTeacherDisciplines();
        this.props.fetchTeacherAudienceRequests();
    }


    handleAddAudienceProposal() {
        this.props.show("teacherAudienceProposalsModal", {
            isCreate: true, selectedIndex: this.state.selectedIndex
        })
        ;
    }

    handleEditAudienceProposalInfo(row) {
        this.props.show("teacherAudienceProposalsModal", {
            row, isCreate: false, selectedIndex: this.state.selectedIndex
        })
        ;
    }

    handleDeleteAudienceProposal(id) {
        this.props.deleteTeacherAudienceRequest(id);
    }


    render() {
        return (
            <div>
                <TeacherAudienceProposalsModal/>
                <Tabs onSelect={(index) => this.setState({selectedIndex: index})}>
                    <TabList>
                        <Tab>Дисципліна</Tab>
                        <Tab>День</Tab>
                    </TabList>

                    <TabPanel>
                        <ReactTable
                            data={this.props.rows.filter(i => !i.isSuggestionsFor1Day).toArray()}
                            filterable
                            columns={[

                                {
                                    Header: "Предмет",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["discipline"]}),
                                    filterAll: true,
                                    accessor: "discipline",
                                }, {
                                    Header: "Аудиторія",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["audience"]}),
                                    filterAll: true,
                                    accessor: "audience",
                                    className: "text-center"
                                },
                                {
                                    Header: "Тип предмету",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["disciplineType"]}),
                                    filterAll: true,
                                    accessor: "disciplineType",
                                    className: "text-center"
                                },
                                {
                                    Header: "Комп'ютерна аудиторія",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["needCompAudience"]}),
                                    filterAll: true,
                                    accessor: "needCompAudience",
                                    className: "text-center"
                                }, {
                                    Header: "Має проектор",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["needProjector"]}),
                                    filterAll: true,
                                    accessor: "needProjector",
                                    className: "text-center"
                                },
                                {

                                    Header: <Popup
                                        trigger={
                                            <Icon name='add' size={"big"}
                                                  color={"green"}
                                                  onClick={this.handleAddAudienceProposal.bind(this)}
                                            />
                                        }
                                        content='Додати запис'
                                        on={'hover'}
                                        style={{zIndex: 3001}}
                                    />,
                                    accessor: "id",
                                    width: 100,
                                    Cell: ({row}) => {
                                        return (<div style={{marginTop: "15px"}}>

                                            <Popup
                                                trigger={<Icon name={"edit"}
                                                               color={"blue"}
                                                               onClick={this.handleEditAudienceProposalInfo.bind(this, row._original)}
                                                />}
                                                content="Редагувати інформацію"
                                                hideOnScroll
                                                on='hover'
                                            />
                                            <Popup
                                                trigger={<Icon name={"trash"}
                                                               color={"red"}
                                                               onClick={this.handleDeleteAudienceProposal.bind(this, row._original.id)}
                                                />}
                                                content="Видалити запис"
                                                hideOnScroll
                                                on='hover'
                                            />
                                        </div>)
                                    },
                                    sortable: false,
                                    filterable: false,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    }
                                },
                            ]}
                            defaultPageSize={10}
                            style={{
                                height: "80vh",
                            }}
                            className="table-bordered table-striped white-bg"
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ReactTable
                            data={this.props.rows.filter(i => i.isSuggestionsFor1Day).toArray()}
                            filterable
                            columns={[

                                {
                                    Header: "Предмет",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["discipline"]}),
                                    filterAll: true,
                                    accessor: "discipline",
                                }, {
                                    Header: "Аудиторія",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["audience"]}),
                                    filterAll: true,
                                    accessor: "audience",
                                    className: "text-center"
                                },
                                {
                                    Header: "Тип предмету",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["disciplineType"]}),
                                    filterAll: true,
                                    accessor: "disciplineType",
                                    className: "text-center"
                                },
                                {
                                    Header: "№ Пар",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["lessonNumbers"]}),
                                    filterAll: true,
                                    accessor: "lessonNumbers",
                                    className: "text-center"
                                },
                                {
                                    Header: "Дата",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["dateValue"]}),
                                    filterAll: true,
                                    accessor: "dateValue",
                                    className: "text-center"
                                },

                                {
                                    Header: "Комп'ютерна аудиторія",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["needCompAudience"]}),
                                    filterAll: true,
                                    accessor: "needCompAudience",
                                    className: "text-center"
                                }, {
                                    Header: "Має проектор",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["needProjector"]}),
                                    filterAll: true,
                                    accessor: "needProjector",
                                    className: "text-center"
                                },
                                {

                                    Header: <Popup
                                        trigger={
                                            <Icon name='add' size={"big"}
                                                  color={"green"}
                                                  onClick={this.handleAddAudienceProposal.bind(this)}
                                            />
                                        }
                                        content='Додати запис'
                                        on={'hover'}
                                        style={{zIndex: 3001}}
                                    />,
                                    accessor: "id",
                                    width: 100,
                                    Cell: ({row}) => {
                                        return (<div style={{marginTop: "15px"}}>

                                            <Popup
                                                trigger={<Icon name={"edit"}
                                                               color={"blue"}
                                                               onClick={this.handleEditAudienceProposalInfo.bind(this, row._original)}
                                                />}
                                                content="Редагувати інформацію"
                                                hideOnScroll
                                                on='hover'
                                            />
                                            <Popup
                                                trigger={<Icon name={"trash"}
                                                               color={"red"}
                                                               onClick={this.handleDeleteAudienceProposal.bind(this, row._original.id)}
                                                />}
                                                content="Видалити запис"
                                                hideOnScroll
                                                on='hover'
                                            />
                                        </div>)
                                    },
                                    sortable: false,
                                    filterable: false,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    }
                                },
                            ]}
                            defaultPageSize={10}
                            style={{
                                height: "80vh",
                            }}
                            className="table-bordered table-striped white-bg"
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />
                    </TabPanel>
                </Tabs>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rows: state.get("teacherProposals").get("audiencesRequests"),
    }
}

export default connect(mapStateToProps, {
    fetchTeacherAudienceRequests,
    fetchAudiences4Select,
    deleteTeacherAudienceRequest,
    fetchLessonTypes,
    show,
    fetchTeacherDisciplines,
})(TeacherProposals)


import React, {PureComponent} from "react";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connect} from "react-redux";
import {fetchDepartments} from "../University/Departments/actions";
import Select from "react-select";
import {NoOptionsMessage} from "../../utils";
import {fetchTeachers} from "../TeachersInfo/Teachers/actions";
import {fetchDisciplines} from "../University/Disciplines/actions";
import {fetchTopics} from "../Topics/actions";
import {fetchTeacherLessons} from "../TeacherLessons/actions";
import {fetchTeacherConsultations} from "../TeacherConsultations/actions";
import {fetchTeacherJournal} from "../TeacherJournal/actions";

class TeacherJournalFilter extends PureComponent {
    state = {
        department: null,
    };


    componentDidMount() {
        this.props.fetchDepartments();
    }

    render() {
        let department = this.state.department;
        if (department === null) {
            const filter = this.props.departments.filter(i => i.isSelected);
            if (filter.size > 0) {
                department = filter.get(0);
                if (this.props.teachers.size < 1) {
                    this.props.fetchTeachers(department.value)
                }
            }

        }
        return (
            <div>
                <Table celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell positive textAlign={"center"} verticalAlign={"middle"}>
                                Кафедра (Факультет)
                            </Table.Cell>
                            <Table.Cell positive textAlign={"center"} verticalAlign={"middle"}>Викладач</Table.Cell>
                            <Table.Cell positive textAlign={"center"} verticalAlign={"middle"}>Предмет</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <Select
                                    options={this.props.departments.toArray()}
                                    onChange={selectedValue => {
                                        this.setState({department: selectedValue});
                                        this.props.fetchTeachers(department.value);
                                    }}
                                    value={department}
                                    noOptionsMessage={NoOptionsMessage}
                                    placeholder={"Виберіть кафедру (факультутет)"}
                                    isClearable={false}
                                />

                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.teachers.toArray()}
                                    onChange={teacher => {
                                        this.props.handleDisciplineChange(null, teacher);
                                        this.props.fetchDisciplines(teacher.value)
                                    }}
                                    value={this.props.teacher}
                                    noOptionsMessage={NoOptionsMessage}
                                    placeholder={"Виберіть викладача"}
                                    isClearable={false}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.disciplines.toArray()}
                                    onChange={discipline => {
                                        this.props.handleDisciplineChange(discipline, this.props.teacher);
                                        const id = discipline.value;
                                        this.props.fetchTopics(id);
                                        this.props.fetchTeacherJournal(discipline.value, this.props.teacher.value);
                                        
                                        this.props.fetchTeacherLessons(discipline.value, this.props.teacher.value);
                                        this.props.fetchTeacherConsultations(this.props.teacher.value);
                                    }}
                                    value={this.props.discipline}
                                    noOptionsMessage={NoOptionsMessage}
                                    placeholder={"Виберіть предмет"}
                                    isClearable={false}
                                />
                                {/* <VirtualizedSelect
                                    options={this.props.disciplines.toArray()}
                                    onChange={selectedValue => {
                                        let discipline = null;
                                        if (selectedValue) {
                                            discipline = selectedValue.value;
                                        }
                                        this.props.handleDisciplineChange(discipline, this.props.teacher);
                                        this.props.fetchTeacherJournal(discipline, this.props.teacher);
                                        this.props.fetchTopics(discipline);
                                        this.props.fetchTeacherLessons(discipline, this.props.teacher);
                                        this.props.fetchTeacherConsultations(this.props.teacher);
                                        this.props.fetchTeacherStatistics(this.props.teacher);
                                    }}
                                    noResultsText={"Немає даних"}
                                    placeholder={"Виберіть предмет..."}
                                />*/}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        departments: state.get("departments"),
        teachers: state.get("teachers"),
        disciplines: state.get("disciplines"),
    }
}

export default connect(mapStateToProps, {
    fetchTeachers,
    fetchDepartments,
    fetchDisciplines,
    fetchTeacherJournal,
    fetchTopics,
    fetchTeacherLessons,
    fetchTeacherConsultations,
    // fetchTeacherStatistics
})(TeacherJournalFilter);
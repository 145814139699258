import React, {PureComponent} from "react";
import matchSorter from "match-sorter";
import {connect} from "react-redux";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";

class TeacherLessons extends PureComponent {
    render() {
        let lectures = [];
        let practicalLessons = [];
        if (this.props.teacherLessons.get("lectures")) {
            lectures = this.props.teacherLessons.get("lectures");
            practicalLessons = this.props.teacherLessons.get("practicalLessons");
        }

        return (
            <div style={{overflow: "auto", height: "54vh"}}>
                {lectures.length > 0 && <div>
                    <h4 className={"text-center"}>Лекції</h4>
                    <ReactTable
                        data={lectures}
                        columns={[
                            {
                                Header: <Popup
                                    trigger={<div>№ заняття</div>}
                                    content="Номер заняття"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["numberLesson"]}),
                                filterAll: true,
                                accessor: "numberLesson",
                                className: "text-center",
                                width:85
                            },
                            {
                                Header: <Popup
                                    trigger={<div>Дати</div>}
                                    content="Дати занять"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                Cell: ({row}) => {
                                    const dates = row.dates.map((date, i) => {
                                        return (
                                            <Popup
                                                key={`date_${i}`}
                                                trigger={
                                                    <span>{date.date}</span>
                                                }
                                                content={date.group}
                                                on={'hover'}
                                                style={{zIndex: 3001}}
                                            />
                                        )
                                    });
                                    return (dates.reduce((prev, curr) => [prev, ', ', curr], []))
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["dates"]}),
                                filterAll: true,
                                accessor: "dates",
                                className: "text-center",
                                width:100
                            },
                            {
                                Header: <Popup
                                    trigger={<div>Тема</div>}
                                    content="Тема заннятя"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["topic"]}),
                                filterAll: true,
                                accessor: "topic",
                            },


                            {
                                Header: <Popup
                                    trigger={<div>К-сть годин</div>}
                                    content="Кількість годин"
                                    hideOnScroll
                                    style={{zIndex: 3001}}
                                    on='hover'
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["numberHours"]}),
                                filterAll: true,
                                accessor: "numberHours",
                                className: "text-center",
                                width:100
                            },
                            {

                                accessor: "isSign",
                                width:50,
                                Cell: ({row}) => {
                                    return (<div key={row.id} style={{marginTop: "15px"}}>
                                        <Popup
                                            trigger={
                                                <Icon name='unlock alternate'
                                                      color={"green"}
                                                      onClick={() => {
                                                      }}
                                                />
                                            }
                                            content='Затвердити запис'
                                            on={'hover'}
                                            style={{zIndex: 3001}}
                                        /></div>)
                                },

                                sortable: false,
                                filterable: false,
                                style: {
                                    cursor: "pointer",
                                    fontSize: 25,
                                    padding: "0",
                                    textAlign: "center",
                                    userSelect: "none"
                                }

                            },
                        ]}
                        defaultPageSize={100}
                        style={{
                            height: "49vh",
                        }}
                        className="table-bordered table-striped white-bg"
                        noDataText="Немає даних"
                        previousText='Попередня сторінка'
                        nextText='Наступна сторінка'
                        loadingText='Завантаження...'
                        pageText='Сторінка'
                        ofText='з'
                        rowsText='рядків'
                    />
                </div>}
                {practicalLessons.length > 0 && <div>
                    <br/>
                    <h4 className={"text-center"}>Практичні</h4>
                    <ReactTable
                        data={practicalLessons}
                        columns={[
                            {
                                Header: <Popup
                                    trigger={<div>№ заняття</div>}
                                    content="Номер заняття"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["numberLesson"]}),
                                filterAll: true,
                                accessor: "numberLesson",
                                className: "text-center",
                                width:85
                            },
                            {
                                Header: <Popup
                                    trigger={<div>Дати</div>}
                                    content="Дати занять"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                Cell: ({row}) => {
                                    const dates = row.dates.map((date, i) => {
                                        return (
                                            <Popup
                                                key={`date_${i}`}
                                                trigger={
                                                    <span>{date.date}</span>
                                                }
                                                content={date.group}
                                                on={'hover'}
                                                style={{zIndex: 3001}}
                                            />
                                        )
                                    });
                                    return (dates.reduce((prev, curr) => [prev, ', ', curr], []))
                                },
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["dates"]}),
                                filterAll: true,
                                accessor: "dates",
                                className: "text-center",
                                width:100
                            },
                            {
                                Header: <Popup
                                    trigger={<div>Тема</div>}
                                    content="Тема заннятя"
                                    hideOnScroll
                                    on='hover'
                                    style={{zIndex: 3001}}
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["topic"]}),
                                filterAll: true,
                                accessor: "topic",
                            },


                            {
                                Header: <Popup
                                    trigger={<div>К-сть годин</div>}
                                    content="Кількість годин"
                                    hideOnScroll
                                    style={{zIndex: 3001}}
                                    on='hover'
                                />,
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["numberHours"]}),
                                filterAll: true,
                                accessor: "numberHours",
                                className: "text-center"
                            },
                            {

                                accessor: "isSign",
                                width:50,
                                Cell: ({row}) => {
                                    return (<div key={row.id} style={{marginTop: "15px"}}>
                                        <Popup
                                            trigger={
                                                <Icon name='unlock alternate'
                                                      color={"green"}
                                                      onClick={() => {
                                                      }}
                                                />
                                            }
                                            content='Затвердити запис'
                                            on={'hover'}
                                            style={{zIndex: 3001}}
                                        /></div>)
                                },
                                sortable: false,
                                filterable: false,
                                style: {
                                    cursor: "pointer",
                                    fontSize: 25,
                                    padding: "0",
                                    textAlign: "center",
                                    userSelect: "none"
                                }

                            },
                        ]}
                        defaultPageSize={100}
                        style={{
                            height: "49vh",
                        }}
                        className="table-bordered table-striped white-bg"
                        noDataText="Немає даних"
                        previousText='Попередня сторінка'
                        nextText='Наступна сторінка'
                        loadingText='Завантаження...'
                        pageText='Сторінка'
                        ofText='з'
                        rowsText='рядків'
                    />
                </div>}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        teacherLessons: state.get("teacherLessons"),
    }
}


export default connect(mapStateToProps)(TeacherLessons)
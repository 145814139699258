import React, {Component} from 'react';
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {show} from "redux-modal/lib/index";
import {connect} from "react-redux";
import ErrorModal from "../ErrorModal";

class Footer extends Component {
    render() {
        return (
            <div className="footer align-middle">
                <ErrorModal/>
                <div className="float-right">

                    <Button icon color={"red"} size={"mini"}
                            onClick={() => {
                                this.props.show("errorModal")
                            }}>
                        <Icon name={"bug"}/> Знайшли помилку?
                    </Button>

                </div>
                <div style={{marginTop: "3px"}}>
                    <strong>&copy; НУ "Острозька академія"</strong> Розробка - Шпак Андрій
                </div>
            </div>
        );
    }

}

export default connect(null, {
    show,
})(Footer)

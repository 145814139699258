import React, {PureComponent} from "react";
import Link from "react-router-dom/es/Link";
import {containsItemInArray} from "../../../../utils/roleMatcher";
import Cookie from 'js-cookie'
import {toast} from "react-toastify";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {ADMIN, EDUCATIONAL_DEPARTMENT, TEACHER,} from "../../../../utils/roles";
import {writeErrors} from "../../../../utils/logs";


class Sidebar extends PureComponent {

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }


    componentDidMount() {
        let oa = Cookie.get('OA.UM-Auth');
        const item = sessionStorage.getItem('login');
        if (item === "true" && oa === undefined) {
            toast.warn('Користувач не авторизований в системі', {
                position: "top-right",
                autoClose: 5000,
            });
            this.props.history.push("/");
        }
        /* else if (rolesMatched(this.props.allowed, Cookie.get('OA.UM-Auth_data') ? JSON.parse(Cookie.get('OA.UM-Auth_data')) : [])) {
             if (item === "true") {
                 toast.warn('Користувач немає доступу до сторінки', {
                     position: "top-right",
                     autoClose: 5000,
                 });
             }
             this.props.history.push("/");
         }*/
    }

    render() {
        const userRoles = Cookie.get('OA.UM-Auth_data') ? JSON.parse(Cookie.get('OA.UM-Auth_data')) : [];
        const {location} = this.props;
        const path = location.pathname;
        const collapseTimetable = containsItemInArray(["/user/lessons", "/department-timetable", "/faculty-timetable"], [path]);

        const collapseProposals = containsItemInArray(["/teachers/proposals", "/teachers/audience-proposals", "/teachers/lesson-proposals"], [path]);
        return (
            <ul className="navigation"
                style={{height: "100vh", overflow: "auto"}}>

                <li className={`${collapseTimetable ? "active" : ""}`}>
                    <Link to={`/user/lessons`}>
    <span className="nav-label"><Icon name={"time"} size={"large"}/> Розклад <Icon
        name={`chevron ${!collapseTimetable ? "left" : "down"}`}
        style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${!collapseTimetable ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${path === "/user/lessons" ? "active" : ""}`}>
                            <Link to={`/user/lessons`}>Переглянути розклад</Link>
                        </li>
                        <li className={`${path === "/department-timetable" ? "active" : ""}`}>
                            <Link to={`/department-timetable`}>Розклад кафедри</Link>
                        </li>
                        <li className={`${path === "/faculty-timetable" ? "active" : ""}`}>
                            <Link to={`/faculty-timetable`}>Розклад факультету</Link>
                        </li>
                    </ul>
                </li>
                <li className={`${(!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, TEACHER], userRoles)) ? "d-none" : ""} ${collapseProposals ? "active" : ""}`}>
                    <Link to={`/teachers/proposals`}>
                                <span className="nav-label">
                                    <Icon name={"calendar check"}
                                          size={"large"}/> Замовлення  <Icon
                                    name={`chevron ${!collapseTimetable ? "left" : "down"}`}
                                    style={{float: "right"}}/>
                                    <br/>
                                викладача</span>
                    </Link>
                    <ul className={`${!collapseProposals ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${path === "/teachers/proposals" ? "active" : ""}`}>
                            <Link to={`/teachers/proposals`}>
                                Повідомлення про зайнятість
                            </Link>
                        </li>
                        <li className={`${path === "/teachers/audience-proposals" ? "active" : ""}`}>
                            <Link to={`/teachers/audience-proposals`}>
                                Замовлення аудиторій
                            </Link>
                        </li>
                        <li className={`${path === "/teachers/lesson-proposals" ? "active" : ""}`}>
                            <Link to={`/teachers/lesson-proposals`}>
                                Повідомлення про пари
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className={`${containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT,TEACHER], userRoles) ? "" : "d-none"} ${path === "/answers" ? "active" : ""}`}>
                    <Link to={`/answers`}>
                        <Icon name={"question circle outline"} size={"large"}/> Оцінювання викладання</Link>
                </li>
                {/*<li className={`${!containsItemInArray([ADMIN, DEPARTMENT, TEACHER, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : "" } ${ path === "/teacher/journal" ? "active" : ""}`}>*/}
                {/*<Link to={`/teacher/journal`}>*/}
                {/*<Icon name={"sticky note"} size={"large"}/> Журнал</Link>*/}
                {/*</li>*/}
            </ul>
        );
    }

}

export default Sidebar;


import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {getDefaultValue, getOptions, NoOptionsMessage} from "../../../../utils";
import Select from "react-select";
import {compose} from "redux";
import {connect} from "react-redux";
import {editTopicInfo} from "../../actions";

class EditTopicsModal extends PureComponent {


    constructor(props) {
        super(props);
        const defaultValue = getDefaultValue();
        this.state = {
            id: props.row.id,
            topic: props.row.topic,
            disciplineTypeId: props.row.disciplineTypeId,
            disciplineType: props.row.disciplineType,
            lessonNumber: props.row.lessonNumber,
            disciplineControl: props.row.disciplineControl,
            disciplineControlId: props.row.disciplineControlId,
            isArchived: props.row.isArchived ? {
                value: props.row.isArchived,
                label: props.row.isArchived
            } : defaultValue,
            teacher: props.teacher,
            discipline: props.discipline,
        }
    }

    render() {
        const {show} = this.props;
        const options = getOptions();
        return (
            <PureModal
                header={"Редагування теми заняття"}
                width={"95vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() => {
                            this.props.hide("editTopicModal")
                        }}>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary icon onClick={() => {
                            this.props.editTopicInfo({
                                id: this.state.id,
                                topic: this.state.topic,
                                disciplineType: this.state.disciplineTypeId ? this.state.disciplineTypeId.value : null,
                                lessonNumber: this.state.lessonNumber,
                                disciplineControl: this.state.disciplineControlId ? this.state.disciplineControlId.id : null,
                                isArchived: this.state.isArchived ? this.state.isArchived.value : '',
                                teacher: this.state.teacher.value,
                                discipline: this.state.discipline.value,
                            });
                            this.props.hide("editTopicModal")
                        }}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("editTopicModal");
                }}
            >

                <div>
                    <Table celled compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className={"w-20"}>
                                    Тема
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({topic: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.topic}
                                        placeholder={"Введіть тему заняття"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-20"}>
                                    Тип заняття
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.props.lessonTypes.take(2).toArray()}

                                        onChange={(selected) => {
                                            this.setState({
                                                disciplineTypeId: selected,
                                                disciplineType: selected.label
                                            })
                                        }}
                                        defaultValue={this.state.disciplineTypeId}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Виберіть тип предмету"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-20"}>
                                    № заняття
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({lessonNumber: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.lessonNumber}
                                        type={'number'}
                                        placeholder={"Введіть № заняття"}
                                        min={0}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-20"}>
                                    Підсумковий контроль
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.props.disciplinesControls.toArray()}
                                        onChange={(selected) => {
                                            this.setState({
                                                disciplineControl: selected ? selected.name : null,
                                                disciplineControlId: selected
                                            })
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        defaultValue={this.state.disciplineControlId}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Виберіть підсумковий контроль"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-20"}>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={options}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>

            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        lessonTypes: state.get("lessonsTypes"),
        disciplinesControls: state.get("disciplinesControls"),
    }
}

export default compose(
    connectModal({
        name: 'editTopicModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTopicInfo
    }))(EditTopicsModal);

import axios from "axios";
import {DELETE_TOPIC, EDIT_TOPIC_INFO, FETCH_TOPICS, INSERT_TOPICS,} from "./types";
import Cookie from "js-cookie";
import {ROOT_URL} from "../../utils/index";
import {writeErrors} from "../../utils/logs";
import {FETCH_TEACHER_LESSONS} from "../TeacherJournal/components/TeacherLessons/types";


export function fetchTopics(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/topics`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TOPICS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function editTopicInfo(row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/topic/edit`, row, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TOPIC_INFO, payload: data.topics})
            dispatch({type: FETCH_TEACHER_LESSONS, payload: data.lessons})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function addTopics(topics) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/topic/create`, topics, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TOPICS, payload: data.topics})
            dispatch({type: FETCH_TEACHER_LESSONS, payload: data.lessons})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTopic(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/topic/delete`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TOPIC, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

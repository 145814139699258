export function getPagePrintStyle() {
return `
.ui.table[class*="middle aligned"], .ui.table [class*="middle aligned"] {
vertical-align: middle;
}
.text-center {
text-align: center !important;
}
.ui.table[class*="center aligned"], .ui.table [class*="center aligned"] {
text-align: center;
}
.table{
width:100%!important;
}
.table,
.table tr td,
.table tr th
{
border: 1px solid black !important;
border-collapse: collapse ;
}
td{
border: 1px solid black !important;
}
.font-weight-bold {
font-weight: 700 !important;
}

.float-left {
float: left !important;
}

.float-right {
float: right !important;
}
`
}
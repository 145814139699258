import axios from "axios";
import {FETCH_TEACHERS} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";
import {ROOT_URL} from "../../../utils/index";


export function fetchTeachers(departmentId) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/journal/teachers`, {
        params: {id: departmentId},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



import {List, Map} from "immutable"
import {FETCH_DEPARTMENT_TIMETABLE, FETCH_FACULTY_DEAN, FETCH_FACULTY_TIMETABLE, FETCH_USER_TIMETABLE} from "./types";

const initialState = Map({
    "user": List(),
    "department": List(),
    "faculty": List(),
    "dean": Map({
        dean: '',
        text: ''
    }),
});
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_TIMETABLE:
            return state.set("user", List(action.payload));
        case FETCH_DEPARTMENT_TIMETABLE:
            return state.set("department", List(action.payload));
        case FETCH_FACULTY_TIMETABLE:
            return state.set("faculty", List(action.payload));
        case FETCH_FACULTY_DEAN:
            return state.set("dean", Map(action.payload));
        default:
            return state;
    }
}
import {List} from "immutable";
import {FETCH_TEACHERS} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHERS:
            return List(action.payload);

        default:
            return state;
    }
}
import axios from "axios";
import {FETCH_DEPARTMENTS} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchDepartments() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/journal/departments`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DEPARTMENTS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


import React, {PureComponent} from "react";
import PureModal from "react-pure-modal";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {connect} from "react-redux";
import {connectModal, hide} from "redux-modal";
import {compose} from "redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {getDefaultValue, getLessons, getOptions, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";
import {editTeacherAudienceRequests, insertTeacherAudienceRequest} from "../../../actions";
import DatePicker from "react-date-picker";

class TeacherAudienceProposalsModal extends PureComponent {
    handleSave = () => {

        let discipline = '';
        if (this.state.disciplineId && this.state.disciplineId.value) {
            discipline = this.state.disciplineId.value;
        }
        let audience = '';
        if (this.state.audienceId && this.state.audienceId.value) {
            audience = this.state.audienceId.value;
        }
        let disciplineType = '';
        if (this.state.disciplineTypeId && this.state.disciplineTypeId.value) {
            disciplineType = this.state.disciplineTypeId.value;
        }
        const object = {
            audience: audience,
            discipline: discipline,
            disciplineType: disciplineType,
            needCompAudience: this.state.needCompAudience.value,
            needProjector: this.state.needProjector.value,
            id: this.state.id,
            dateValue: this.state.date,
            lessonNumbers: this.state.values.map(i => i.value).join(',')
        };
        if (this.state.isCreate) {
            this.props.insertTeacherAudienceRequest(object);
        } else {
            this.props.editTeacherAudienceRequests(object);
        }

        this.props.hide("teacherAudienceProposalsModal");
    };

    constructor(props) {
        super(props);
        let row = {};
        if (!props.isCreate) {
            row = props.row;
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: props.isCreate ? '' : row.id,
            isCreate: props.isCreate,
            disciplineId: props.isCreate ? null : row.disciplineId,
            disciplineTypeId: props.isCreate ? null : row.disciplineTypeId,
            audienceId: props.isCreate ? null : row.audienceId,
            needCompAudience: props.isCreate ? defaultValue : {
                value: row.needCompAudience,
                label: row.needCompAudience
            },
            needProjector: props.isCreate ? defaultValue : {value: row.needProjector, label: row.needProjector},
            selectedIndex: props.selectedIndex,
            values: props.isCreate || props.selectedIndex === 0 ? [] : row.values,
            date: row.start ? new Date(row.date) : new Date(),
        }
    }

    render() {
        const {show} = this.props;
        const options = getOptions();

        return (
            <PureModal
                header={"Створення та редагування інформації про замовлення аудиторії"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherAudienceProposalsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teacherAudienceProposalsModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className={"w-20"}>
                                Дисципліна
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.disciplines}
                                    defaultValue={this.state.disciplineId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable
                                    onChange={(selectedValue) => this.setState({
                                        disciplineId: selectedValue,

                                    })}
                                    placeholder={"Виберіть предмет"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Аудиторія
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.audiences}
                                    onChange={(selected) => {
                                        this.setState({
                                            audienceId: selected,
                                        })
                                    }}
                                    defaultValue={this.state.audienceId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Виберіть аудиторію"}
                                />

                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Тип предмету
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.lessonTypes}
                                    onChange={(selected) => {
                                        this.setState({
                                            disciplineTypeId: selected,
                                        })
                                    }}
                                    defaultValue={this.state.disciplineTypeId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Виберіть тип предмету"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        {this.state.selectedIndex !== 0 && <Table.Row>
                            <Table.Cell>
                                Дата
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({date: value})}
                                    locale={"uk-UA"}
                                    value={this.state.date}
                                />
                            </Table.Cell>
                        </Table.Row>}
                        {this.state.selectedIndex !== 0 && <Table.Row>
                            <Table.Cell>
                                № пар
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={getLessons()}
                                    onChange={(selected) => {
                                        this.setState({
                                            values: selected,
                                        })
                                    }}
                                    defaultValue={this.state.values}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    isMulti
                                    placeholder={"Виберіть номери пар"}
                                />
                            </Table.Cell>
                        </Table.Row>}
                        <Table.Row>
                            <Table.Cell>
                                Аудиторія є комп'ютерним класом
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={options}
                                    onChange={(selected) => {
                                        this.setState({
                                            needCompAudience: selected || getDefaultValue()
                                        })
                                    }}
                                    defaultValue={this.state.needCompAudience}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Аудиторія є комп'ютерним класом"}

                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Аудиторія має проектор
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={options}
                                    onChange={(selected) => {
                                        this.setState({
                                            needProjector: selected || getDefaultValue()
                                        })
                                    }}
                                    defaultValue={this.state.needProjector}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Аудиторія має проектор"}
                                />

                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        rows: state.get("teacherProposals").get("audiencesRequests"),
        audiences: state.get("data4Select").get("audiences"),
        lessonTypes: state.get("lessonsTypes"),
        disciplines: state.get("data4Select").get('disciplines'),
    }
}

export default compose(
    connectModal({
        name: 'teacherAudienceProposalsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        insertTeacherAudienceRequest,
        editTeacherAudienceRequests,
    }))(TeacherAudienceProposalsModal);
import {writeErrors} from "../../utils/logs";
import {FETCH_TEACHER_JOURNAL} from "./types";
import {ROOT_URL} from "../../utils";
import axios from "axios";
import Cookie from "js-cookie";

export function fetchTeacherJournal(id, teacherId) {
    const token = Cookie.get('OA.UM-Auth');
    if (!id) {
        id = "test";
    }
    if (!teacherId) {
        teacherId = 'test';
    }
    const request = axios.get(`${ROOT_URL}/v1/journal`, {
        params: {id, teacherId},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_JOURNAL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
import React, {PureComponent} from "react";
import PureModal from "react-pure-modal";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {connect} from "react-redux";
import {connectModal, hide} from "redux-modal";
import {compose} from "redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {editTeacherLessonProposal, insertTeacherLessonProposal} from "../../../actions";
import DatePicker from "react-date-picker";
import {getLessons, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

class TeacherLessonProposalsModal extends PureComponent {
    handleSave = () => {
        let discipline = '';
        if (this.state.disciplineId && this.state.disciplineId.value) {
            discipline = this.state.disciplineId.value;
        }
        let disciplineType = '';
        if (this.state.disciplineTypeId && this.state.disciplineTypeId.value) {
            disciplineType = this.state.disciplineTypeId.value;
        }
        const object = {
            id: this.state.id,
            start: this.state.date,
            discipline: discipline,
            disciplineType: disciplineType,
            lessonNumbers: this.state.values.map(i => i.value).join(',')
        };
        if (this.state.isCreate) {
            this.props.insertTeacherLessonProposal(object);
        } else {
            this.props.editTeacherLessonProposal(object);
        }

        this.props.hide("teacherLessonProposalsModal");
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.row) {
            row = props.row;
        }
        this.state = {
            id: props.isCreate ? '' : row.id,
            isCreate: props.isCreate,
            date: row.start ? new Date(row.start) : new Date(),
            disciplineId: props.isCreate ? null : row.disciplineId,
            disciplineTypeId: props.isCreate ? null : row.disciplineTypeId,
            values: props.isCreate ? [] : row.values.map(({label, value}) => {
                return {
                    value,
                    label
                }
            })
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про пари"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherLessonProposalsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teacherLessonProposalsModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>

                        <Table.Row>
                            <Table.Cell className={"w-20"}>
                                Дисципліна
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.disciplines}
                                    defaultValue={this.state.disciplineId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    onChange={(selectedValue) => this.setState({
                                        disciplineId: selectedValue,
                                        discipline: selectedValue ? selectedValue.label : null,

                                    })}
                                    placeholder={"Виберіть предмет"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Тип предмету
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.lessonTypes}
                                    onChange={(selected) => {
                                        this.setState({
                                            disciplineTypeId: selected,
                                            disciplineType: selected ? selected.label : ''
                                        })
                                    }}
                                    defaultValue={this.state.disciplineTypeId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Виберіть тип предмету"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                № пар
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={getLessons()}
                                    onChange={(selected) => {
                                        this.setState({
                                            values: selected,
                                        })
                                    }}
                                    defaultValue={this.state.values}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    isMulti
                                    placeholder={"Виберіть номери пар"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Дата початоку
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({date: value})}
                                    locale={"uk-UA"}
                                    value={this.state.date}
                                />
                            </Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
            </PureModal>
        );
    }

}


function mapStateToProps(state) {
    return {
        disciplines: state.get("data4Select").get('disciplines'),
        lessonTypes: state.get("lessonsTypes"),
    }
}

export default compose(
    connectModal({
        name: 'teacherLessonProposalsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeacherLessonProposal,
        insertTeacherLessonProposal,
    }))(TeacherLessonProposalsModal);
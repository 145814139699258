import React, {Component} from 'react';
import {connect} from "react-redux";
import ReactNotificationCenter from './components';
import "./styles.css";
import {writeErrors} from "../../../../../../utils/logs";
import {fetchNotifications, toggleNotifications} from "./actions";


class NotificationCenter extends Component {

    componentDidMount() {
        this.props.fetchNotifications();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        if (this.props.notifications.size === 0){
            return (<div/>);
        }
        return (
            <ReactNotificationCenter
                notifications={this.props.notifications}
                notificationTitle={'Сповіщення'}
                noNotificationText={'Немає нових сповіщень'}
                onItemClick={(item) => {
                    this.props.toggleNotifications(item.id)
                }}
                visible
                mapToItem={{
                    id: "id",
                    message: 'massage',
                    new: 'active',
                    date: 'date'
                }}
                className="label label-warning"
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.get("notifications")
    }
}

export default connect(mapStateToProps, {fetchNotifications, toggleNotifications})(NotificationCenter)

import React, {PureComponent} from "react";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connect} from "react-redux";

class LessonInfo extends PureComponent {


    render() {
        const {teacherJournal} = this.props;
        return (
            <div>
                {teacherJournal.size > 0 && <Table celled compact={"very"}
                                                   textAlign={"center"}
                                                   verticalAlign={"middle"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Лекції</Table.HeaderCell>
                            <Table.HeaderCell>Практичні</Table.HeaderCell>
                            <Table.HeaderCell>Групи/Потік</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                {teacherJournal.get("hoursLectures")}
                            </Table.Cell>
                            <Table.Cell>{teacherJournal.get("hoursPractical")}</Table.Cell>
                            <Table.Cell>{teacherJournal.get("groups")}</Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>}
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        teacherJournal: state.get("teacherJournal"),
    }
}

export default connect(mapStateToProps)(LessonInfo);
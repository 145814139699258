import axios from "axios";
import {CLEAR_NOTIFICATIONS, FETCH_NOTIFICATIONS, TOGGLE_NOTIFICATIONS} from "./types";
import Cookie from "js-cookie";
import {ROOT_URL} from "../../../../../../utils";
import {writeErrors} from "../../../../../../utils/logs";


export function fetchNotifications() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/notifications`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_NOTIFICATIONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function clearNotifications() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/clear-notifications`, null, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: CLEAR_NOTIFICATIONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function toggleNotifications(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/toggle-notification`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: TOGGLE_NOTIFICATIONS})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}
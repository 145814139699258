import React, {Component, Fragment, useReducer} from "react";
import {connect} from "react-redux";
import Select from 'react-select';
import 'react-sticky-table/dist/react-sticky-table.css';
import {Cell, Row, StickyTable} from "react-sticky-table";
import "./styles.css";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {fetchAnswers, fetchUserEmail} from "./actions";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {fetchStudyYears} from "../University/StudyYear/actions";

function reducer(state, action) {
    switch (action.type) {
        case 'course':
            if (state.course.findIndex(x => x === action.payload) !== -1) return {
                ...state,
                course: state.course.filter(x => x !== action.payload)
            };
            else return {
                ...state,
                course: [...state.course, action.payload]
            };
        case 'universityGroup':
            if (state.universityGroup.findIndex(x => x === action.payload) !== -1) return {
                ...state,
                universityGroup: state.universityGroup.filter(x => x !== action.payload)
            };
            else return {
                ...state,
                universityGroup: [...state.universityGroup, action.payload]
            };
        case 'lessonType':
            if (state.lessonType.findIndex(x => x === action.payload) !== -1) return {
                ...state,
                lessonType: state.lessonType.filter(x => x !== action.payload)
            };
            else return {
                ...state,
                lessonType: [...state.lessonType, action.payload]
            };
        case "reset":
            return {
                course: [],
                universityGroup: [],
                lessonType: [],
        
            };
        default:
            return;
    }
}

function AnswersTable({answers}) {
 
    const [state, setState] = useReducer(reducer, {
        course: [],
        universityGroup: [],
        lessonType: [],

    });
    const teacher = answers.get("teacher");
    return (
        <div>
            {answers.get("header") && <div className={"answers"}>
                <StickyTable stickyColumnCount={0}>
                    <Row>
                        {answers.get("header") && answers.get("header").map((header, index) => {
                            return (<Cell key={`header_${index}`}>
                                {header}
                            </Cell>)
                        })}
                        {answers.get("questions") && answers.get("questions").map((question, index) => {
                            return (<Cell key={`question_${index}`}>
                                <Popup content={question} trigger={
                                    <span>{question.substring(0, 6)}...</span>}/>
                            </Cell>)
                        })}
                    </Row>
                    <Row>
                        {teacher && <Cell>{teacher.teacherFullName}
                        <br/>
                            {teacher.teacherPosition}
                            <br/>
                            {teacher.teacherRank}
                        </Cell>}
                        <Cell/>
                        <Cell/>
                        <Cell/>
                        <Cell/>
                        <Cell/>
                        <Cell/>
                        {teacher && getAnswers(teacher.id, teacher.answers)}
                    </Row>
                    {teacher && teacher.lessons.map((lesson, index) => {
                        return <Fragment key={lesson.id + index}>
                            <Row onClick={() => setState({type: 'course', payload: lesson.id})}>
                                <Cell className={"text-center"}><Icon
                                    name={`${state.course.findIndex(x => x === lesson.id) === -1 ? "chevron down" : "chevron up"}`}
                                    style={{fontSize: '2em'}}/></Cell>
                                <Cell>{lesson.course} ({lesson.count}/{lesson.total})</Cell>
                                <Cell/>
                                <Cell/>
                                <Cell/>
                                <Cell/>
                                <Cell/>
                                {getAnswers(lesson.id, lesson.answers)}
                            </Row>
                            {state.course.findIndex(x => x === lesson.id) === -1 && lesson.lessonTypes.map((lessonType, lessonTypeIndex) => {
                                return (
                                    <Fragment key={`${lessonType.id}_${index}_${lessonTypeIndex}`}>
                                        <Row onClick={() => setState({type: 'lessonType', payload: lessonType.id})}>
                                            <Cell/>
                                            <Cell/>
                                            <Cell className={"text-center"}><Icon
                                                name={`${state.lessonType.findIndex(x => x === lessonType.id) === -1 ? "chevron down" : "chevron up"}`}
                                                style={{fontSize: '2em'}}/></Cell>
                                            <Cell>{lessonType.title} ({lessonType.count}/{lessonType.total})</Cell>
                                            <Cell/>
                                            <Cell/>
                                            <Cell/>
                                            {getAnswers(lessonType.id, lessonType.answers)}
                                        </Row>
                                        {state.lessonType.findIndex(x => x === lessonType.id) === -1 && lessonType.universityGroups.map((universityGroup, universityGroupIndex) => {
                                            const collapsed = state.universityGroup.findIndex(x => x === universityGroup.id) === -1;
                                            return (<Fragment
                                                key={`${universityGroup.id}_${index}_${lessonTypeIndex}_${universityGroupIndex}`}>
                                                <Row onClick={() => setState({
                                                    type: 'universityGroup',
                                                    payload: universityGroup.id
                                                })}>
                                                    <Cell/>
                                                    <Cell/>
                                                    <Cell/>
                                                    <Cell/>
                                                    <Cell className={"text-center"}><Icon
                                                        name={`${collapsed ? "chevron down" : "chevron up"}`}
                                                        style={{fontSize: '2em'}}/></Cell>
                                                    <Cell>{universityGroup.name} ({universityGroup.count}/{universityGroup.total})</Cell>
                                                    <Cell>
                                                        <Popup content={universityGroup.specialtyName} trigger={
                                                            <span>{universityGroup.specialtyName.substring(0, 6)}...</span>}
                                                        />
                                                    </Cell>
                                                    {getAnswers(universityGroup.id, universityGroup.answers)}
                                                </Row>
                                                {collapsed && universityGroup.students.map(student => {
                                                    return (<Row key={student.id}>
                                                        <Cell/>
                                                        <Cell/>
                                                        <Cell/>
                                                        <Cell/>
                                                        <Cell/>
                                                        <Cell/>
                                                        <Cell/>
                                                        {getAnswers(student.id, student.answers)}
                                                    </Row>)
                                                })}
                                            </Fragment>)
                                        })}
                                    </Fragment>
                                )
                            })}

                        </Fragment>
                    })}
                </StickyTable>
            </div>}
        </div>
    )
}


const getAnswers = (key, answers = []) => {
    return answers.map((answer, index) => {
        return (
            <Cell key={key + answer + index}>
                {answer.length > 6 ? <Popup content={answer}
                                            trigger={<span>{answer.substring(0, 6)}</span>}/>

                    : answer}
            </Cell>
        )
    })
};

class Answers extends Component {

    componentDidMount() {
        this.props.fetchStudyYears();
        this.props.fetchUserEmail()
    }

    render() {
        return (
            <>
                <div className={"select"}>
                    <Select
                        placeholder={"Виберіть навчальний рік"}
                        onChange={(studyYear) => {
                            this.props.fetchAnswers(studyYear.label, this.props.answers.get("email"))
                        }}
                        options={this.props.studyYears}
                    />
                </div>
                <AnswersTable answers={this.props.answers}/>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        answers: state.get("answers"),
        studyYears: state.get("studyYears"),
    }
}

export default connect(mapStateToProps, {fetchAnswers, fetchStudyYears, fetchUserEmail})(Answers);

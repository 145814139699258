let url = `https://dekanat.oa.edu.ua/api`;
/*if (process.env.NODE_ENV === 'development') {
    url = `http://10.211.55.11:8012/api`;
}*/
export const ROOT_URL = url;
export const APP_NAME = `Інформаційна система "Викладач"`;


export const NoOptionsMessage = () => "Немає даних";
export const loadingMessage = () => "Завантаження...";

export const DAYS = [
    "",
    "Понеділок",
    "Вівторок",
    "Середа",
    "Четверг",
    "Пятниця",
    "Субота",
    "Неділя"];

export function getDefaultOption() {
    return {
        value: '',
        label: ''
    }
}

export function getOptions() {
    return [
        {value: "Ні", label: "Ні"},
        {value: "Так", label: "Так"},
    ];
}

export function getDefaultValue() {
    return {value: "Ні", label: "Ні"};

}

export function getUserDepartmentOption() {
    return {
        value: localStorage.getItem("OA.UM.Dekanat_userDepartmentId"),
        label: localStorage.getItem("OA.UM.Dekanat_userDepartment")
    };

}

export function getUniversityGroupTypes() {
    return [{label: "Потокові групи", value: 1,},
        {label: "Академічні групи", value: 2},
        {label: "Підгрупа", value: 3},
        {label: "Нетипові групи (Інші)", value: 4},
    ];

}

export function getDefaultUniversityGroupType() {
    return {label: "Академічні групи", value: 2};

}

export function getSemesters() {
    return [{label: 1, value: 1,},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5},
        {label: 6, value: 6},
        {label: 7, value: 7},
        {label: 8, value: 8},
        {label: 9, value: 9},
        {label: 10, value: 10},
        {label: 11, value: 11},
        {label: 12, value: 12},
    ]
};
export function getLessons() {
    return[{
        label: 0, value: "0"
    }, {
        label: 1, value: 1
    }, {
        label: 2, value: 2
    }, {
        label: 3, value: 3
    }, {
        label: 4, value: 4
    }, {
        label: 5, value: 5
    }, {
        label: 6, value: 6
    }, {
        label: 7, value: 7
    }, {
        label: 8, value: 8
    },]
}

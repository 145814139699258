import axios from "axios";
import {FETCH_STUDY_YEAR} from "./types";
import Cookies from "js-cookie"
import {ROOT_URL} from "../../../utils";

export const fetchStudyYears = () => {
    const token = Cookies.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/studyYears4Select`,
        {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDY_YEAR, payload: data});
        })
            .catch(() => {
            });
    }
};

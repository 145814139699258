import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker';
import {applyMiddleware, createStore} from 'redux'
import {Provider} from 'react-redux'

import createHistory from 'history/createBrowserHistory'
import thunk from "redux-thunk";

import {Route} from 'react-router-dom'

import {ConnectedRouter, routerMiddleware} from 'react-router-redux'

import {Map} from "immutable";

import logger from "redux-logger";
//Css
import "bootstrap-css-only/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
import 'react-toastify/dist/ReactToastify.css';

import "pretty-checkbox/dist/pretty-checkbox.css";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import 'react-tabs/style/react-tabs.css';
import "./index.css";
// Components
import reducers from './reducers';
import AppRoute from "./components/AppRoute";
import MainLayout from "./components/Layouts";

import Answers from "./scenes/Answers";
import FreeAudiences from "./scenes/FreeAudiences";
import Login from "./scenes/Login";
import TeacherJournal from "./scenes/TeacherJournal";
import DepartmentTimetable from "./scenes/Timetable/DepartmentTimetable";
import UserLessons from "./scenes/Timetable/UserTimetable";
import FacultyTimetable from "./scenes/Timetable/FacultyTimetable";

import TeacherLessonProposals from "./scenes/Proposals/TeacherLessonProposals";
import TeacherAudienceProposals from "./scenes/Proposals/TeacherAudienceProposals";
import TeacherProposals from "./scenes/Proposals/TeacherProposals";

import {ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT, HEAD_OF_DEPARTMENT, TEACHER} from "./utils/roles";
import NotFound from "./components/NotFound";
import NotAuth from "./components/NotAuth";
import {ToastContainer} from "react-toastify";
// import LessonsTimes from "./scenes/University/LessonsTimes";

// Or wherever you keep your reducers

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();


// Build the middleware for intercepting and dispatching navigation actions
let middleware;
if (process.env.NODE_ENV === 'development') {
    middleware =
        applyMiddleware(routerMiddleware(history), thunk, logger);
} else {
    middleware =
        applyMiddleware(routerMiddleware(history), thunk);
}

const initialState = Map();

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
    reducers,
    initialState,
    middleware
);


ReactDOM.render(
    <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={history}>
            <div>
                <Route exact path="/" component={Login}/>


                <AppRoute allowed={[ADMIN, TEACHER, EDUCATIONAL_DEPARTMENT, HEAD_OF_DEPARTMENT]}
                          path="/university/free-audiences"
                          title={`Вільні аудиторії`} layout={MainLayout}
                          component={FreeAudiences}/>


                <AppRoute exact allowed={[ADMIN, TEACHER, EDUCATIONAL_DEPARTMENT]}
                          path="/user/lessons" title={`Розклад занять`} layout={MainLayout}
                          component={UserLessons}/>
                <AppRoute exact allowed={[ADMIN, TEACHER, EDUCATIONAL_DEPARTMENT]}
                          path="/department-timetable" title={`Розклад кафедри`} layout={MainLayout}
                          component={DepartmentTimetable}/>
                <AppRoute exact allowed={[ADMIN, TEACHER, EDUCATIONAL_DEPARTMENT]}
                          path="/faculty-timetable" title={`Розклад факультету`} layout={MainLayout}
                          component={FacultyTimetable}/>

                <AppRoute exact allowed={[ADMIN, TEACHER]}
                          path="/teachers/proposals" title={`Повідомлення про зайнятість`}
                          layout={MainLayout}
                          component={TeacherProposals}/>
                <AppRoute exact allowed={[ADMIN, TEACHER]}
                          path="/teachers/audience-proposals"
                          title={`Замовлення аудиторій`}
                          layout={MainLayout}
                          component={TeacherAudienceProposals}/>
                <AppRoute exact allowed={[ADMIN, TEACHER]}
                          path="/teachers/lesson-proposals"
                          title={`Повідомлення про пари`}
                          layout={MainLayout}
                          component={TeacherLessonProposals}/>



                <AppRoute exact allowed={[ADMIN, TEACHER, DEPARTMENT, EDUCATIONAL_DEPARTMENT, HEAD_OF_DEPARTMENT]}
                          path="/teacher/journal"
                          title={`Журнал`}
                          layout={MainLayout}
                          component={TeacherJournal}/>
                <AppRoute exact allowed={[ADMIN, TEACHER, EDUCATIONAL_DEPARTMENT]}
                          path="/answers"
                          title={`Оцінювання якості викладання`}
                          layout={MainLayout}
                          component={Answers}/>


                <Route path={"/not-auth"} component={NotAuth}/>
                <Route path={"/not-found"} component={NotFound}/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
registerServiceWorker();

import axios from "axios";
import {DELETE_TEACHER_CONSULTATION, FETCH_TEACHER_CONSULTATIONS_JOURNAL} from "./types";
import Cookie from "js-cookie";
import {ROOT_URL} from "../../utils/index";
import {writeErrors} from "../../utils/logs";


export function fetchTeacherConsultations(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/journal/teacher-consultations`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_CONSULTATIONS_JOURNAL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function calcTeacherConsultations(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/journal/teacher-consultations/calc`, null, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_CONSULTATIONS_JOURNAL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherConsultations(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/journal/teacher-consultations/delete`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_CONSULTATION, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



import React, {PureComponent} from "react";
import {Popup} from 'semantic-ui-react';
import {connect} from "react-redux";
import {deleteTeacherProposal, fetchTeacherProposals,} from "../actions"
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import TeacherProposalsModal from "./components/TeacherProposalsModal";
import {show} from "redux-modal";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

class TeacherProposals extends PureComponent {
    state = {
        selectedIndex: 0
    };


    componentDidMount() {
        this.props.fetchTeacherProposals();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddTeacherProposal() {
        this.props.show("teacherProposalsModal", {isCreate: true, selectedIndex: this.state.selectedIndex});
    }

    handleEditTeacherProposalInfo(row) {
        this.props.show("teacherProposalsModal", {row, isCreate: false, selectedIndex: this.state.selectedIndex});
    }

    handleDeleteTeacherProposal(id) {
        this.props.deleteTeacherProposal(id, this.state.selectedIndex);
    }


    render() {
        return (
            <div>
                <TeacherProposalsModal/>
                <Tabs onSelect={(index) => this.setState({selectedIndex: index})}>
                    <TabList>
                        <Tab>Дні</Tab>
                        <Tab>Заняття</Tab>
                    </TabList>

                    <TabPanel>
                        <ReactTable
                            data={this.props.events.toArray()}
                            filterable
                            columns={[

                                {
                                    Header: <Popup
                                        trigger={<span>Подія</span>}
                                        content={'Причина зайнятості викладача. Наприклад - "Конференція"'}
                                        hideOnScroll
                                        on='hover'
                                    />,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["title"]}),
                                    filterAll: true,
                                    accessor: "title",
                                }, {
                                    Header: <Popup
                                        trigger={<span>Початок події</span>}
                                        content={"Дата початоку події"}
                                        hideOnScroll
                                        on='hover'
                                    />,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["startValue"]}),
                                    filterAll: true,
                                    accessor: "startValue",
                                    className: "text-center"
                                },
                                {
                                    Header: <Popup
                                        trigger={<span>Початок події</span>}
                                        content={"Дата закінчення події"}
                                        hideOnScroll
                                        on='hover'
                                    />,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["endValue"]}),
                                    filterAll: true,
                                    accessor: "endValue",
                                    className: "text-center"
                                },

                                {

                                    Header: <Popup
                                        trigger={
                                            <Icon name='add' size={"big"}
                                                  color={"green"}
                                                  onClick={this.handleAddTeacherProposal.bind(this)}
                                            />
                                        }
                                        content='Додати запис'
                                        on={'hover'}
                                        style={{zIndex: 3001}}
                                    />,
                                    accessor: "id",
                                    width: 100,
                                    Cell: ({row}) => {
                                        return (<div style={{marginTop: "15px"}}>

                                            <Popup
                                                trigger={<Icon name={"edit"}
                                                               color={"blue"}
                                                               onClick={this.handleEditTeacherProposalInfo.bind(this, row._original)}
                                                />}
                                                content="Редагувати інформацію"
                                                hideOnScroll
                                                on='hover'
                                            />
                                            <Popup
                                                trigger={<Icon name={"trash"}
                                                               color={"red"}
                                                               onClick={this.handleDeleteTeacherProposal.bind(this, row._original.id)}
                                                />}
                                                content="Видалити запис"
                                                hideOnScroll
                                                on='hover'
                                            />
                                        </div>)
                                    },
                                    sortable: false,
                                    filterable: false,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    }
                                },
                            ]}
                            defaultPageSize={10}
                            style={{
                                height: "74vh",
                            }}
                            className="table-bordered table-striped white-bg"
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />
                    </TabPanel>
                    <TabPanel>

                        <ReactTable
                            data={this.props.eventsOnLessons.toArray()}
                            filterable
                            columns={[
                                {
                                    Header: <Popup
                                        trigger={<span>Назва</span>}
                                        content={'Причина відсутності. Наприклад - "Ректорат"'}
                                        hideOnScroll
                                        on='hover'
                                    />,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["title"]}),
                                    filterAll: true,
                                    accessor: "title",
                                }, {
                                    Header: "№ Пар",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["lessonNumbers"]}),
                                    filterAll: true,
                                    accessor: "lessonNumbers",
                                    className: "text-center"
                                },
                                {
                                    Header: "Дата початоку",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["startValue"]}),
                                    filterAll: true,
                                    accessor: "startValue",
                                    className: "text-center"
                                },
                                {
                                    Header: "Дата закінчення",
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["endValue"]}),
                                    filterAll: true,
                                    accessor: "endValue",
                                    className: "text-center"
                                },

                                {

                                    Header: <Popup
                                        trigger={
                                            <Icon name='add' size={"big"}
                                                  color={"green"}
                                                  onClick={this.handleAddTeacherProposal.bind(this)}
                                            />
                                        }
                                        content='Додати запис'
                                        on={'hover'}
                                        style={{zIndex: 3001}}
                                    />,
                                    accessor: "id",
                                    width: 100,
                                    Cell: ({row}) => {
                                        return (<div style={{marginTop: "15px"}}>

                                            <Popup
                                                trigger={<Icon name={"edit"}
                                                               color={"blue"}
                                                               onClick={this.handleEditTeacherProposalInfo.bind(this, row._original)}
                                                />}
                                                content="Редагувати інформацію"
                                                hideOnScroll
                                                on='hover'
                                            />
                                            <Popup
                                                trigger={<Icon name={"trash"}
                                                               color={"red"}
                                                               onClick={this.handleDeleteTeacherProposal.bind(this, row._original.id)}
                                                />}
                                                content="Видалити запис"
                                                hideOnScroll
                                                on='hover'
                                            />
                                        </div>)
                                    },
                                    sortable: false,
                                    filterable: false,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    }
                                },
                            ]}
                            defaultPageSize={10}
                            style={{
                                height: "74vh",
                            }}
                            className="table-bordered table-striped white-bg"
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />

                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        events: state.get("teacherProposals").get('teacherEventProposals'),
        eventsOnLessons: state.get("teacherProposals").get("teacherEventOnLessonsProposals")
    }
}

export default connect(mapStateToProps, {
    fetchTeacherProposals,
    deleteTeacherProposal,
    show
})(TeacherProposals)
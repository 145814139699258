import intersection from "lodash.intersection";

// check if any user role matches any allowed role
export function rolesMatched(allowedRoles, userRoles) {
    return intersection(allowedRoles, userRoles).length === 0;
}

export function containsItemInArray(allowedRoles, userRoles) {
    return intersection(allowedRoles, userRoles).length > 0;
}

import {List} from "immutable";
import {DELETE_TOPIC, EDIT_TOPIC_INFO, FETCH_TOPICS, INSERT_TOPICS} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TOPICS:
        case EDIT_TOPIC_INFO:
        case INSERT_TOPICS:
            return List(action.payload);
        case DELETE_TOPIC:
           return state.filter(i => i.id !== action.payload);
        default:
            return state;
    }
}
